@font-face {
	font-family: 'Arial MT Pro Cyr';
	src: url('../fonts/Arial/ArialMTProCyr-Regular.eot');
	src: url('../fonts/Arial/ArialMTProCyr-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Arial/ArialMTProCyr-Regular.woff2') format('woff2'),
	url('../fonts/Arial/ArialMTProCyr-Regular.woff') format('woff'),
	url('../fonts/Arial/ArialMTProCyr-Regular.ttf') format('truetype'),
	url('../fonts/Arial/ArialMTProCyr-Regular.svg#ArialMTProCyr-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Arial MT Pro Cyr';
	src: url('../fonts/Arial/ArialMTProCyr-Bold.eot');
	src: url('../fonts/Arial/ArialMTProCyr-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Arial/ArialMTProCyr-Bold.woff2') format('woff2'),
	url('../fonts/Arial/ArialMTProCyr-Bold.woff') format('woff'),
	url('../fonts/Arial/ArialMTProCyr-Bold.ttf') format('truetype'),
	url('../fonts/Arial/ArialMTProCyr-Bold.svg#ArialMTProCyr-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}


html {
	font-size: 16px;
}
@media (min-width: 1680px) {
	html {
		font-size: 1vw;
	}
}
html, body {
	overflow-x: hidden;
}
@media (max-width: 767px) {
	html, body {
		-webkit-overflow-scrolling: touch;
	}
}
body {
	font-size: 1rem;
	font-weight: 400;
	line-height: normal;
	color: #000;
	background-color: #fff;
	font-family: 'Arial MT Pro Cyr', sans-serif;
}
body.active {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
body.active main, body.active .fishka, body.active .warning {
	-webkit-filter: blur(4px);
	        filter: blur(4px);
	pointer-events: none;
}
@media (max-width: 767px) {
	.hidden-mob {
		display: none;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.hidden-md {
		display: none;
	}
}
.bg-red {
	background-color: #ba0c2f;
}
.bg-red .breadcrumbs li {
	color: #fff;
	opacity: .36;
}
.bg-red .breadcrumbs li a {
	color: #fff;
}
.bg-red .breadcrumbs li:after {
	background-color: #fff;
}
.spacer-111 {
	height: 6.9375rem;
}
::-moz-selection {
	background-color: #000;
	color: #fff;
}
::selection {
	background-color: #000;
	color: #fff;
}
h1, h2, h3, h4, h5, h6, p, ul li, ol li, blockquote, cite, a {
	font-family: 'Arial MT Pro Cyr', sans-serif;
	color: #000;
}
h1 {
	font-size: 1.875em;
	font-weight: 700;
	line-height: 1.13;
	margin-bottom: 3.4375rem;
	color: #fff;
}
@media (max-width: 767px) {
	h1 {
		line-height: 1.55;
		font-size: 1.375em;
		margin-bottom: 2.75rem;
	}
}
h1.h1-black {
	color: #000;
	font-size: 2.625em;
	line-height: 1.19;
}
@media (max-width: 767px) {
	h1.h1-black {
		line-height: 1.42;
		font-size: 1.5em;
	}
}
@media (max-width: 767px) {
	h1.h1-with-form {
		margin-bottom: 1.25rem;
	}
}
h1.h1-pages {
	margin-bottom: 1.5625rem;
	margin-top: 1.875rem;
	color: #000;
}
h1.h1-line {
	position: relative;
	padding-bottom: 2.1875rem;
}
@media (max-width: 767px) {
	h1.h1-line {
		padding-bottom: 1.8125rem;
	}
}
h1.h1-line:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 3.125rem;
	background-color: #000;
	height: .0625rem;
}
h1.h1-404 {
	line-height: 1.56;
	font-size: 2em;
	margin-bottom: 1.625rem;
}
@media (max-width: 767px) {
	h1.h1-404 {
		font-size: 1.5em;
		line-height: 1.42;
		position: relative;
		padding-bottom: 1.1875rem;
		margin-bottom: 1.5rem;
	}
	h1.h1-404:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 3.125rem;
		background-color: #fff;
		height: .0625rem;
	}
}
h2 {
	font-size: 1.875em;
	font-weight: 700;
	line-height: 1.13;
	margin-bottom: 2.75rem;
	padding-bottom: 2.5625rem;
	position: relative;
}
@media (max-width: 767px) {
	h2 {
		font-size: 1.5em;
		padding-bottom: 2rem;
		margin-bottom: 2.5rem;
	}
}
h2:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 3.125rem;
	background-color: #000;
	height: .0625rem;
}
@media (max-width: 767px) {
	h2.h2-no-line {
		padding-bottom: 0;
		font-size: 1.25em;
	}
}
h2.h2-no-line:after {
	display: none;
}
h2.h2-404 {
	margin-bottom: 0;
	padding-bottom: 0;
	font-size: 11.125em;
	letter-spacing: .7875rem;
	text-align: center;
	color: #fff;
	margin-left: 4.6875rem;
	z-index: 10;
}
@media (max-width: 767px) {
	h2.h2-404 {
		opacity: .3;
		font-size: 5.625em;
		letter-spacing: .4rem;
		text-align: left;
		margin-left: 0;
	}
}
h2.h2-404:after {
	display: none;
}
h3 {
	font-size: 3.375em;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 1.8125rem;
}
@media (max-width: 767px) {
	h3 {
		font-size: 2em;
		margin-bottom: 1.625rem;
	}
}
h3.h3-line {
	line-height: 1.75rem;
	font-size: 1.375em;
	padding-bottom: 1.125rem;
	border-bottom: .0625rem solid #000;
	margin-top: 1rem;
}
@media (max-width: 767px) {
	h3.h3-line {
		margin-top: 0;
		font-size: 1.25em;
	}
}
h3.h3-sm {
	line-height: 1.27;
	font-size: 1.375em;
}
@media (max-width: 767px) {
	h3.h3-sm {
		font-size: 1.25em;
	}
}
h3.h3-sm.h3-line {
	padding-bottom: 2.5625rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	h3.h3-sm.h3-line {
		margin-top: 0;
	}
}
@media (max-width: 767px) {
	h3.h3-sm.h3-line {
		padding-bottom: 1.375rem;
	}
}
h4 {
	font-size: 1.125em;
	font-weight: 700;
	line-height: 1.56;
	margin-bottom: 1.875rem;
}
@media (max-width: 767px) {
	h4 {
		font-size: 1em;
		line-height: 1.63;
		margin-bottom: 1.9375rem;
	}
}
h4.h4-line {
	font-size: 1.375em;
	border-bottom: .0625rem solid #000;
	padding-bottom: 2.4375rem;
	margin-bottom: 2.75rem;
}
@media (max-width: 767px) {
	h4.h4-line {
		font-size: 1.25em;
		padding-bottom: 1.1875rem;
		margin-bottom: 2.125rem;
	}
}
h5 {
	font-size: 1.25em;
	font-weight: 700;
	line-height: 1.3;
	margin-bottom: 1.875rem;
}
h5.h5-success {
	font-size: 1.375em;
	margin-bottom: 1.5rem;
}
h6 {
	font-size: 1em;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 1rem;
	color: #879bcb;
}
p {
	font-size: .875em;
	font-weight: 400;
	line-height: 1.86;
	margin-bottom: 1rem;
}
p a {
	color: #d74d39;
	text-decoration: underline;
}
p a:hover {
	color: #d74d39;
	text-decoration: none;
}
hr {
	border: 0;
	height: .0625rem;
	background-color: #000;
	margin: .9375rem 0;
}
.hr-responsibility {
	margin-top: 6.5625rem;
	background-color: #ccc;
}
@media (max-width: 767px) {
	.hr-responsibility {
		display: none;
	}
}
.text-white {
	color: #fff;
}
.text-white p {
	color: #fff;
}
.text-black {
	color: #000;
}
.text-bold {
	font-weight: 700;
}
.text-center {
	text-align: center;
}
.text-red {
	color: #ff1530;
}
.text-gray {
	color: rgba(0,0,0,.3);
}
.text-border {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: #f4f4f5;
	padding: 1.5625rem 1.375rem 1.1875rem;
	margin-bottom: 1.5rem;
}
@media (max-width: 767px) {
	.text-border {
		padding-top: 1.1875rem;
		padding-bottom: .6875rem;
		margin-bottom: 2.6875rem;
	}
}
.text-border_heading {
	opacity: .2;
	-webkit-text-stroke: .1rem #000;
	        text-stroke: .1rem #000;
	font-size: 2.375em;
	font-weight: bold;
	line-height: .89;
	color: rgba(0,0,0,0);
	margin-right: 1.1875rem;
}
@media (max-width: 767px) {
	.text-border_heading {
		line-height: 1.13;
		font-size: 1.875em;
		margin-right: .875rem;
	}
}
.text-border_text {
	font-size: 1.5em;
	font-weight: bold;
	line-height: 1.42;
}
@media (max-width: 767px) {
	.text-border_text {
		line-height: 1.5;
		font-size: 1.25em;
	}
}
.hr-alcohol {
	margin-bottom: 1.75rem;
}
blockquote {
	position: relative;
	font-size: 1em;
	padding-top: 1.25rem;
	padding-bottom: 3.25rem;
	padding-left: 6.25rem;
	margin-top: 2.5rem;
	margin-bottom: 2.75rem;
}
@media (max-width: 767px) {
	blockquote {
		margin-top: 4.375rem;
		margin-bottom: 4.625rem;
		padding-left: 1.625rem;
		padding-bottom: 4.875rem;
	}
}
blockquote:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	background-color: #ba0c2f;
	width: .25rem;
}
blockquote p {
	font-size: 1em;
	font-weight: bold;
	line-height: 1.75;
}
blockquote div {
	position: absolute;
	bottom: 0;
	left: 6.25rem;
	font-size: .875em;
	color: rgba(0,0,0,.3);
	margin-bottom: 1.125rem;
}
@media (max-width: 767px) {
	blockquote div {
		left: 1.625rem;
	}
}
article iframe {
	width: 100%;
}
article img {
	width: 100%;
	height: auto;
}
article ul li, article ol li {
	font-size: .875em;
	line-height: 1.86;
	position: relative;
}
article ul li {
	padding-left: 1.375rem;
}
article ul li:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: .625rem;
	width: .625rem;
	height: .1875rem;
	background-color: #000;
}
article h2 {
	padding-bottom: 1.25rem;
	margin-bottom: 2rem;
}
@media (max-width: 767px) {
	article h2 {
		font-size: 1.25em;
		line-height: 1.7;
		padding-bottom: 1.0625rem;
	}
}
article h2:after {
	width: 100%;
}
article h3 {
	font-size: 1.5em;
	font-weight: bold;
	line-height: 1.42;
	margin-bottom: 1.125rem;
}
@media (max-width: 767px) {
	article h3 {
		font-size: 1.25em;
		line-height: 1.7;
	}
}
article.article-regular h1 {
	font-size: 2.5em;
	margin-bottom: 1.1875rem;
	line-height: 1.2;
}
@media (max-width: 767px) {
	article.article-regular h1 {
		line-height: 1.38;
		font-size: 1.625em;
		margin-bottom: 1.4375rem;
	}
}
article.article-regular h1.article-internship_h1 {
	margin-bottom: 4.25rem;
}
@media (max-width: 767px) {
	article.article-regular h1.article-internship_h1 {
		margin-bottom: 2.9375rem;
	}
}
article.article-regular h2 {
	line-height: 1.17;
	font-size: 1.5em;
	padding-bottom: 0;
	margin-top: 4.8125rem;
	margin-bottom: 2.1875rem;
}
article.article-regular h2:after {
	display: none;
}
@media (max-width: 767px) {
	article.article-regular h2 {
		line-height: 1.6;
		font-size: 1.25em;
		margin-bottom: 2.0625rem;
	}
}
article.article-regular h3 {
	margin-top: 2.8125rem;
	font-size: 1.375em;
	margin-bottom: 2rem;
}
@media (max-width: 767px) {
	article.article-regular h3 {
		line-height: 1.6;
		font-size: 1.25em;
		margin-bottom: 1.875rem;
	}
}
article.article-regular h4 {
	margin-top: 2.625rem;
	font-size: 1.25em;
	margin-bottom: 1.875rem;
}
@media (max-width: 767px) {
	article.article-regular h4 {
		line-height: 1.6;
		font-size: 1.125em;
		margin-bottom: 1.75rem;
	}
}
article.article-regular h5 {
	margin-top: 2.4375rem;
	font-size: 1.125em;
	margin-bottom: 1.75rem;
}
@media (max-width: 767px) {
	article.article-regular h5 {
		line-height: 1.6;
		font-size: 1.125em;
		margin-bottom: 1.625rem;
	}
}
article.article-regular h6 {
	margin-top: 2.25rem;
	font-size: 1em;
	margin-bottom: 1.625rem;
}
@media (max-width: 767px) {
	article.article-regular h6 {
		line-height: 1.6;
		font-size: 1em;
		margin-bottom: 1.5rem;
	}
}
article.article-regular a {
	text-decoration: underline;
	color: #d74d39;
}
article.article-regular ul, article.article-regular ol {
	margin-bottom: 1.375rem;
}
@media (max-width: 767px) {
	article.article-regular ul, article.article-regular ol {
		margin-bottom: 1rem;
	}
}
article.article-regular ul li, article.article-regular ol li {
	line-height: 1.86;
	margin-bottom: 1rem;
	padding-left: 1.6875rem;
}
article.article-regular p {
	margin-bottom: 1.375rem;
}
@media (max-width: 767px) {
	article.article-regular p {
		margin-bottom: 1rem;
	}
}
article.article-regular p.article-badge {
	margin-bottom: .625rem;
}
article.article-regular img {
	margin-top: 3rem;
	margin-bottom: 5.125rem;
}
@media (max-width: 767px) {
	article.article-regular img {
		margin-top: 3.375rem;
	}
}
article.article-regular figure {
	margin-bottom: 5.125rem;
}
@media (max-width: 767px) {
	article.article-regular figure {
		margin-bottom: 4.8125rem;
	}
}
article.article-regular figure figcaption {
	font-size: .875em;
	color: rgba(0,0,0,.4);
}
article.article-regular figure img {
	margin-bottom: 1.1875rem;
}
.article-regular .btn {
	text-decoration: none;
	color: #fff;
}
.article-regular_hr {
	width: 75rem;
	margin-left: -6.25rem;
	margin-top: 0;
	margin-bottom: 4.625rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.article-regular_hr {
		width: 60rem;
		margin-left: -5rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.article-regular_hr {
		width: 100%;
		margin-left: 0rem;
	}
}
@media (max-width: 767px) {
	.article-regular_hr {
		width: 100%;
		margin-left: 0rem;
	}
}
.article-regular__container {
	position: relative;
	padding-bottom: 11rem;
}
@media (max-width: 767px) {
	.article-regular__container {
		padding-bottom: 10.3125rem;
	}
}
.article-regular__container:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	height: .0625rem;
	background-color: #000;
}
@media (max-width: 767px) {
	.article-regular__container:after {
		left: 1.666rem;
		right: 1.666rem;
	}
}
.article-regular__container-share {
	margin-top: 3.125rem;
}
.article-regular__img {
	height: 18.75rem;
	margin-bottom: 4.9375rem;
	margin-top: 0;
}
@media (max-width: 767px) {
	.article-regular__img {
		margin-left: -1.666rem;
		margin-right: -1.666rem;
		height: 6rem;
		margin-bottom: 1.5625rem;
	}
}
.article-regular__img img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	   object-fit: cover;
	margin-bottom: 0 !important;
	margin-top: 0 !important;
}
.article-internship_heading {
	opacity: .06;
	-webkit-text-stroke: .125rem #000;
	        text-stroke: .125rem #000;
	font-size: 7.5em;
	font-weight: bold;
	color: rgba(0,0,0,0);
	position: absolute;
	left: -6.25rem;
	top: -2.1875rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.article-internship_heading {
		left: -5.625rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.article-internship_heading {
		left: 0;
	}
}
@media (max-width: 767px) {
	.article-internship_heading {
		position: static;
		font-size: 3.125em;
	}
}
.article-internship_img {
	position: absolute;
	bottom: 1.9375rem;
	right: 4.6875rem;
	max-width: 10rem;
	margin: 0 !important;
	z-index: -1;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.article-internship_img {
		right: 0;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.article-internship_img {
		right: 0;
	}
}
@media (max-width: 767px) {
	.article-internship_img {
		max-width: 6.375rem;
		bottom: 8.9375rem;
		right: 2.9375rem;
	}
}
.article-internship_hr {
	margin-bottom: 1.875rem;
}
@media (max-width: 767px) {
	.article-internship_hr {
		margin-bottom: 3.75rem;
	}
}
.article-ethic-img {
	margin-bottom: 9.375rem;
}
@media (max-width: 767px) {
	.article-ethic-img {
		margin-bottom: 6.25rem;
	}
}
footer {
	background-color: #061c4f;
	padding-top: 4.375rem;
}
@media (max-width: 767px) {
	footer {
		padding-top: 3.125rem;
	}
}
footer.footer-ua {
	padding-bottom: 15vh;
}
address {
	width: 100%;
}
.social-links {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
.social-links li {
	margin-right: 2.4375rem;
	margin-bottom: .9375rem;
}
@media (max-width: 767px) {
	.social-links li {
		margin-right: 1.8125rem;
	}
}
.social-links li a {
	display: inline-block;
}
.social-links li a svg {
	height: 1.5rem;
	width: auto;
}
.social-links li a svg g, .social-links li a svg path, .social-links li a svg circle, .social-links li a svg polygon {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	fill: #fff;
}
.social-links li a:hover svg g, .social-links li a:hover svg path, .social-links li a:hover svg circle, .social-links li a:hover svg polygon {
	fill: #ff385a;
}
.footer-p {
	margin-bottom: 3.375rem;
}
@media (max-width: 767px) {
	.footer-p {
		margin-bottom: 3.1875rem;
	}
}
.footer-p p {
	color: #fff;
}
.footer-p a {
	text-decoration: underline;
	color: #fff;
}
.footer-p a:hover {
	color: #ff385a;
}
.footer-sm {
	opacity: .5;
	font-size: .8125em;
	color: #8a9ecd;
	margin-bottom: 0;
	line-height: 1;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.footer-sm {
		font-size: .75em;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.footer-sm {
		font-size: .5625em;
	}
}
.footer-sm.is-right {
	float: right;
	font-size: 1.25em;
	line-height: normal;
	letter-spacing: -.00625rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.footer-sm.is-right {
		font-size: 1em;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.footer-sm.is-right {
		font-size: .6875em;
	}
}
@media (max-width: 767px) {
	.footer-sm.is-right {
		line-height: 1.41;
		font-size: 1.0625em;
		margin-top: 1.625rem;
	}
}
a.footer-sm:hover {
	color: #ff385a;
}
.footer-line {
	margin-left: auto;
	margin-right: auto;
	width: .0625rem;
	height: 28.1875rem;
	background-color: #475c8e;
}
.footer-bottom {
	padding-bottom: 1.5rem;
	padding-top: 1.375rem;
	position: relative;
	margin-top: 2.6875rem;
}
@media (max-width: 767px) {
	.footer-bottom {
		padding-top: 1.5625rem;
		margin-top: 6.0625rem;
	}
}
.footer-bottom:before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: .0625rem;
	background-color: #475c8e;
}
@media (max-width: 767px) {
	.footer-bottom:before {
		left: 1.625rem;
		right: 1.625rem;
	}
}
.footer-left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}
@media (min-width: 768px) and (max-width: 991px) {
	.footer-left {
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
}
.footer-icons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
@media (max-width: 767px) {
	.footer-icons {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		padding-right: .4375rem;
		position: relative;
		top: .625rem;
	}
}
.footer-icons li {
	margin: 0 .5625rem;
}
@media (max-width: 767px) {
	.footer-icons li {
		margin: 0 .4375rem;
	}
}
.footer-icons li img {
	width: 1.5rem;
	height: 1.5rem;
}
.footer-logo {
	-ms-flex-item-align: start;
	align-self: flex-start;
	display: block;
	max-width: 15.5625rem;
	width: 100%;
}
.footer-logo img {
	width: 100%;
}
@media (max-width: 767px) {
	.footer-logo {
		max-width: 9.9375rem;
		margin-bottom: 3.5625rem;
	}
}
.footer-list {
	margin-bottom: 2.3125rem;
}
@media (max-width: 767px) {
	.footer-list {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		-webkit-transition: all .2s ease;
		     -o-transition: all .2s ease;
		        transition: all .2s ease;
		-webkit-transform: translateX(100vw);
		    -ms-transform: translateX(100vw);
		        transform: translateX(100vw);
	}
	.footer-list.foo-swiped {
		-webkit-transform: none;
		    -ms-transform: none;
		        transform: none;
	}
}
.footer-list_text {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	color: rgba(138,158,205,.5);
}
.footer-list li {
	padding-right: .625rem;
	margin-bottom: .3125rem;
}
@media (max-width: 767px) {
	.footer-list li {
		padding-right: 0;
	}
}
.footer-list li.footer-list__back {
	display: none;
}
@media (max-width: 767px) {
	.footer-list li.footer-list__back {
		display: block;
	}
}
.footer-list li.footer-list__back a {
	padding-left: 1.5625rem;
	color: rgba(204,204,204,.5);
}
.footer-list li.footer-list__back a:after, .footer-list li.footer-list__back a:before {
	left: 0;
	right: auto;
	background-color: rgba(204,204,204,.5);
	display: block;
}
.footer-list li.footer-list__back a:before {
	-webkit-transform: rotate(-45deg);
	    -ms-transform: rotate(-45deg);
	        transform: rotate(-45deg);
}
.footer-list li.footer-list__back a:after {
	-webkit-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
}
.footer-list li a {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: .8125em;
	line-height: 1.5;
	position: relative;
	padding-right: .625rem;
	-webkit-transition: none;
	     -o-transition: none;
	        transition: none;
}
@media (max-width: 767px) {
	.footer-list li a {
		padding-right: 0;
	}
}
.footer-list li a svg {
	width: 1.875rem;
	height: auto;
}
.footer-list li a svg g {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.footer-list li a.footer-list__direct:after {
	display: none;
}
@media (max-width: 767px) {
	.footer-list li a.footer-list__direct:before {
		display: none;
	}
}
.footer-list li a.footer-list__direct:hover {
	padding-left: 0;
}
.footer-list li a.footer-list__direct:hover svg g {
	fill: #ff385a;
}
.footer-list li a:after {
	content: '';
	display: block;
	position: absolute;
	width: .3125rem;
	height: .3125rem;
	background-color: #ff385a;
	top: .4375rem;
	left: 0;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	opacity: 0;
}
.footer-list li a:hover {
	opacity: 1;
}
.footer-list li a:hover .footer-list_text {
	-webkit-transform: translateX(1.25rem);
	    -ms-transform: translateX(1.25rem);
	        transform: translateX(1.25rem);
	color: #ff385a;
}
.footer-list li a:hover:after {
	opacity: 1;
}
@media (max-width: 767px) {
	.footer-list li a {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		color: rgba(204,204,204,.5);
		line-height: 1.47;
		font-size: .9375em;
		height: 3rem;
		border-bottom: .0625rem solid rgba(204,204,204,.5);
	}
	.footer-list li a:before, .footer-list li a:after {
		content: '';
		display: block;
		position: absolute;
		width: .4375rem;
		height: .0625rem;
		background-color: rgba(204,204,204,.5);
		right: .625rem;
		left: auto;
		opacity: 1;
		display: none;
	}
	.footer-list li a:before {
		-webkit-transform: rotate(45deg);
		    -ms-transform: rotate(45deg);
		        transform: rotate(45deg);
		top: 1.25rem;
	}
	.footer-list li a:after {
		-webkit-transform: rotate(-45deg);
		    -ms-transform: rotate(-45deg);
		        transform: rotate(-45deg);
		bottom: 1.375rem;
		top: auto;
	}
}
.footer-col {
	position: static;
}
@media (max-width: 767px) {
	.footer-panel h6 {
		height: 3rem;
		border-bottom: .0625rem solid rgba(71,92,142,.5);
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		margin-bottom: 0;
		-webkit-transition: all .2s ease;
		     -o-transition: all .2s ease;
		        transition: all .2s ease;
	}
	.footer-panel h6.foo-swiped {
		-webkit-transform: translateX(-100vw);
		    -ms-transform: translateX(-100vw);
		        transform: translateX(-100vw);
	}
	.footer-panel h6:before, .footer-panel h6:after {
		content: '';
		display: block;
		position: absolute;
		width: .4375rem;
		height: .0625rem;
		background-color: #2d4274;
		right: .625rem;
		left: auto;
	}
	.footer-panel h6:before {
		-webkit-transform: rotate(45deg);
		    -ms-transform: rotate(45deg);
		        transform: rotate(45deg);
		top: 1.1875rem;
	}
	.footer-panel h6:after {
		-webkit-transform: rotate(-45deg);
		    -ms-transform: rotate(-45deg);
		        transform: rotate(-45deg);
		bottom: 1.375rem;
		top: auto;
	}
}
header {
	position: relative;
	height: 100vh;
	overflow: hidden;
}
@media (min-width: 768px) and (max-width: 812px) and (orientation: landscape) {
	header {
		height: auto;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	header {
		height: auto;
	}
}
@media (max-width: 567px) {
	header {
		min-height: auto;
		height: 100vh;
	}
}
.header-index {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	padding-bottom: 4.25rem;
}
@media (min-width: 768px) and (max-width: 812px) and (orientation: landscape) {
	.header-index {
		padding-top: 8.75rem;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.header-index {
		padding-top: 8.75rem;
	}
}
@media (max-width: 567px) {
	.header-index {
		padding-bottom: 2.5rem;
	}
}
.header-index__back {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	overflow: hidden;
}
.header-index__back:before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: .36;
	background-color: #000;
	z-index: 3;
}
.header-index .slider-header {
	height: 100%;
}
.header-index .slider-header .swiper-slide {
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.header-index .slider-header .swiper-slide img {
	min-width: 100%;
	min-height: 100%;
	-o-object-fit: cover;
	   object-fit: cover;
}
.header-dream_h1 {
	font-size: 2.625em;
	line-height: 1.19;
}
@media (max-width: 767px) {
	.header-dream_h1 {
		font-size: 1.5em;
		line-height: 2.125rem;
	}
}
.header-dream_h1.h1-line {
	margin-bottom: 2.75rem;
}
@media (max-width: 767px) {
	.header-dream_h1.h1-line {
		padding-bottom: 1.375rem;
		margin-bottom: 1.5rem;
	}
}
.header-dream_h1.h1-line:after {
	background-color: #fff;
}
@media (max-width: 767px) {
	.header-together {
		height: auto;
	}
}
@media (max-width: 767px) {
	.header-together .header-blind__wrapper {
		min-height: unset;
		height: auto;
	}
}
.header-factories_h1 {
	font-size: 2.625em;
	line-height: 1.19;
	position: relative;
	padding-bottom: 2.1875rem;
	margin-bottom: 2.75rem;
}
@media (max-width: 767px) {
	.header-factories_h1 {
		font-size: 1.875em;
		line-height: 1.13;
		padding-bottom: 1.3125rem;
		margin-bottom: 1.5rem;
	}
}
.header-factories_h1:after {
	content: '';
	display: block;
	position: absolute;
	background-color: #fff;
	left: 0;
	width: 3.125rem;
	height: .0625rem;
	bottom: 0;
}
@media (max-width: 767px) {
	.header-blind {
		height: auto;
	}
	.header-blind .row {
		min-height: 100vh;
	}
	.header-blind .link-more {
		margin-top: 2.5rem;
	}
}
.header-blind:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 50%;
	background-color: #ba0c2f;
}
@media (max-width: 991px) {
	.header-blind:after {
		width: 100%;
	}
}
.header-blind__container {
	position: relative;
}
.header-blind__blind {
	position: absolute;
	background-color: #ba0c2f;
	top: 0;
	bottom: 0;
	left: 0;
	width: calc(50% + 21rem);
	z-index: 50;
	-webkit-clip-path: polygon(0 0, 100% 0%, 38.625rem 100%, 0% 100%);
	        clip-path: polygon(0 0, 100% 0%, 38.625rem 100%, 0% 100%);
}
@media (max-width: 767px) {
	.header-blind__blind {
		display: none;
	}
}
.header-blind .breadcrumbs li {
	color: #fff;
	opacity: .36;
}
.header-blind .breadcrumbs li:after {
	background-color: #fff;
}
.header-blind .breadcrumbs li a {
	color: #fff;
}
.header-blind__subheading {
	font-size: 1.625em;
	color: #fff;
	line-height: 1.31;
	margin-bottom: 3rem;
}
@media (max-width: 767px) {
	.header-blind__subheading {
		font-size: 1em;
		margin-bottom: 2.1875rem;
	}
}
.header-blind__wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	    flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-bottom: 4.25rem;
	position: relative;
	z-index: 100;
	height: calc(100vh - 14.75rem);
}
@media (max-width: 767px) {
	.header-blind__wrapper {
		padding-bottom: 2.25rem;
	}
}
@media (min-width: 768px) and (max-width: 812px) and (orientation: landscape) {
	.header-blind__wrapper {
		height: auto;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.header-blind__wrapper {
		height: auto;
	}
}
@media (max-width: 567px) {
	.header-blind__wrapper {
		height: auto;
	}
}
.header-breadcrumbs {
	max-height: unset;
	min-height: auto;
	height: auto;
}
@media (min-width: 568px) and (max-width: 767px) {
	.header-breadcrumbs {
		max-height: unset;
		min-height: auto;
	}
}
@media (max-width: 567px) {
	.header-breadcrumbs {
		height: auto;
	}
}
.header-404 {
	padding-bottom: 3.625rem;
}
@media (max-width: 767px) {
	.header-404 {
		padding-bottom: 0;
	}
}
.header-inner {
	padding-bottom: 4.4375rem;
}
@media (max-width: 767px) {
	.header-inner {
		padding-bottom: 0;
	}
	.header-inner .link-more {
		display: none;
	}
}
.header-inner .breadcrumbs {
	margin-bottom: 3.375rem;
}
.header-brand {
	padding-bottom: 5.375rem;
}
@media (max-width: 767px) {
	.header-brand {
		padding-bottom: 6.125rem;
	}
}
.header-brand .link-more {
	margin-bottom: 1.6875rem;
}
.header-brand__form form {
	margin-bottom: 0;
}
.header-brand__form input {
	background-color: #f4f4f5;
}
@media (max-width: 767px) {
	.header-brand .breadcrumbs {
		margin-bottom: 1.4375rem;
	}
}
.header-content {
	z-index: 999;
}
nav {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9991;
	background-color: #fff;
}
nav.hidden {
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}
nav.unscroll {
	background-color: transparent;
}
nav.unscroll.nav-index .nav-menu_text {
	color: #fff;
}
nav.unscroll.nav-index .nav-container {
	border-color: #fff;
}
@media (max-width: 767px) {
	nav.unscroll.nav-index .nav-container:after {
		background-color: #fff;
	}
}
nav.unscroll.nav-index .nav-icon span {
	background-color: #fff;
}
nav.unscroll.nav-index .nav-lang_link {
	color: #fff;
}
nav.unscroll.nav-index .nav-lang__list:before {
	background-color: #fff;
}
nav.unscroll.nav-index .nav-lang:after {
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-left: .25rem solid transparent;
	border-right: .25rem solid transparent;
	border-top: .25rem solid #fff;
}
@media (min-width: 768px) and (max-width: 991px) {
	nav.unscroll.nav-blind .nav-lang:after {
		content: '';
		display: block;
		position: absolute;
		width: 0;
		height: 0;
		border-left: .25rem solid transparent;
		border-right: .25rem solid transparent;
		border-top: .25rem solid #fff;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	nav.unscroll.nav-blind .nav-lang_link {
		color: #fff;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	nav.unscroll.nav-blind .nav-container {
		border-color: #fff;
	}
}
nav.unscroll.nav-blind .nav-container:after {
	background-color: #fff;
}
nav.unscroll.nav-blind .nav-logo_red {
	fill: #c82a4a;
}
nav.unscroll.nav-blind .nav-logo_blue {
	fill: #950824;
}
@media (max-width: 991px) {
	nav.unscroll.nav-blind .nav-menu_text {
		color: #fff;
	}
}
@media (max-width: 991px) {
	nav.unscroll.nav-blind .nav-icon span {
		background-color: #fff;
	}
}
nav.unscroll .nav-blind_line {
	opacity: 1;
}
nav.unscroll.nav-404.unscroll .nav-container {
	border-color: #fff;
}
nav.unscroll.nav-404.unscroll .nav-icon span {
	background-color: #fff;
}
nav.unscroll.nav-404.unscroll .nav-lang:after {
	border-top-color: #fff;
}
nav.unscroll.nav-404.unscroll .nav-menu_text, nav.unscroll.nav-404.unscroll .nav-lang_link {
	color: #fff;
}
.nav-back {
	position: absolute;
	left: 0;
	right: 0;
	top: 5.75rem;
	height: 100vh;
	background-color: #000;
	z-index: -100;
	visibility: hidden;
	opacity: 0;
}
.nav-blind_line {
	position: absolute;
	bottom: 0;
	left: calc(50% - (75rem / 2));
	right: calc(50% - (75rem / 2));
	height: .0625rem;
	background-color: #fff;
	opacity: 0;
	width: 56.25rem;
}
@media (min-width: 1200px) {
	.nav-blind_line {
		width: 56.125rem;
	}
}
@media (min-width: 1600px) {
	.nav-blind_line {
		height: 2px;
		width: 56.25rem;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.nav-blind_line {
		width: 56.875rem;
	}
}
@media (max-width: 991px) {
	.nav-blind_line {
		display: none;
	}
}
.nav-blind_hd {
	width: 17.5rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.nav-blind_hd {
		width: 11rem;
	}
}
.nav-container {
	border-bottom: .0625rem solid #000;
	padding: 1.6875rem 0 1.625rem;
}
@media (min-width: 1600px) {
	.nav-container {
		border-width: 2px;
	}
}
@media (max-width: 767px) {
	.nav-container {
		padding-top: 1.125rem;
		padding-bottom: 1.1875rem;
		border: none;
		position: relative;
		max-width: 100%;
	}
	.nav-container:after {
		content: '';
		display: block;
		position: absolute;
		background-color: #000;
		height: .0625rem;
		bottom: 0;
		right: 1.625rem;
		left: 1.625rem;
		-webkit-transition: all .2s ease;
		     -o-transition: all .2s ease;
		        transition: all .2s ease;
	}
}
.nav-logo {
	width: 15.5625rem;
	height: 2.375rem;
	display: block;
}
@media (max-width: 767px) {
	.nav-logo {
		width: 9.9375rem;
		height: 1.5625rem;
		position: relative;
	}
}
.nav-logo_red, .nav-logo_blue {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.nav-logo img, .nav-logo svg {
	width: 100%;
	height: auto;
}
.nav-logo:hover .nav-logo_red {
	fill: #de3355;
}
.nav-logo:hover .nav-logo_blue {
	fill: #254ba1;
}
.nav-menu {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	margin-top: -.125rem;
	margin-left: -.0625rem;
}
@media (max-width: 767px) {
	.nav-menu {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-ms-flex-direction: row-reverse;
		    flex-direction: row-reverse;
	}
}
.nav-menu_text {
	margin-left: 1rem;
	font-size: .875em;
	line-height: 1.86;
	padding-top: .0625rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.nav-menu_text {
		margin-left: 0;
		margin-right: 1rem;
	}
}
.nav-menu_text.close {
	display: none;
}
@media (min-width: 768px) {
	.nav-menu:hover .nav-menu_text {
		opacity: .5;
	}
	.nav-menu:hover .nav-icon span:nth-child(1) {
		-webkit-transform: translateY(.125rem);
		    -ms-transform: translateY(.125rem);
		        transform: translateY(.125rem);
	}
	.nav-menu:hover .nav-icon span:nth-child(4) {
		-webkit-transform: translateY(-.125rem);
		    -ms-transform: translateY(-.125rem);
		        transform: translateY(-.125rem);
	}
}
.nav-icon {
	width: 1.625rem;
	height: 1.375rem;
	position: relative;
	cursor: pointer;
	-webkit-transform: rotate(0deg);
	    -ms-transform: rotate(0deg);
	        transform: rotate(0deg);
	float: right;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.nav-icon span {
	display: block;
	position: absolute;
	height: .125rem;
	width: 100%;
	background-color: #000;
	border-radius: .125rem;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	    -ms-transform: rotate(0deg);
	        transform: rotate(0deg);
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.nav-icon span:nth-child(1) {
	top: 0;
}
.nav-icon span:nth-child(2), .nav-icon span:nth-child(3) {
	top: .625rem;
}
.nav-icon span:nth-child(4) {
	top: 1.25rem;
}
.nav-lang {
	position: relative;
	cursor: pointer;
	float: right;
	max-width: 4.5rem;
	width: 100%;
	padding-top: .125rem;
	z-index: 9999;
}
.nav-lang:hover:after {
	-webkit-transform: rotate(180deg);
	    -ms-transform: rotate(180deg);
	        transform: rotate(180deg);
	opacity: .5;
}
.nav-lang:hover .nav-lang_link {
	opacity: .5;
}
.nav-lang:hover .nav-lang__list {
	opacity: 1;
	visibility: visible;
	z-index: 10;
}
.nav-lang:after {
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-left: .25rem solid transparent;
	border-right: .25rem solid transparent;
	border-top: .25rem solid #000;
	right: .4375rem;
	top: calc(50% - .1875rem);
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.nav-lang_link {
	padding-left: .4375rem;
	padding-right: .625rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: .875em;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.nav-lang_link {
		font-size: .9375em;
	}
}
.nav-lang_link:hover {
	opacity: .5;
}
.nav-lang__list {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	padding-top: 3.375rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.nav-lang__list {
		padding-top: 3.1875rem;
	}
}
@media (max-width: 767px) {
	.nav-lang__list {
		padding-top: 2.5rem;
	}
}
.nav-lang__list:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 3.375rem;
	background-color: #000;
	height: .0625rem;
	z-index: 300;
}
@media (min-width: 1600px) {
	.nav-lang__list:before {
		top: 3.40625rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.nav-lang__list:before {
		top: 3.1875rem;
	}
}
@media (max-width: 767px) {
	.nav-lang__list:before {
		top: 2.5rem;
	}
}
.nav-lang__list li:last-child a:after {
	display: none;
}
.nav-lang__list li a {
	background-color: #fff;
	color: #000;
	height: 2.375rem;
	font-size: .875em;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-transform: uppercase;
	text-align: center;
	padding-top: .3125rem;
	position: relative;
}
@media (max-width: 767px) {
	.nav-lang__list li a {
		font-size: .9375em;
		background-color: #000;
		color: #fff;
		height: 3rem;
	}
}
.nav-lang__list li a:after {
	content: '';
	display: block;
	position: absolute;
	background-color: #ccc;
	height: .0625rem;
	left: 0;
	right: 0;
	bottom: 0;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.nav-lang__list li a:hover {
	background-color: #000;
	color: #fff;
}
.nav-lang__list li a:hover:after {
	opacity: 0;
}
@media (max-width: 767px) {
	.col-lang {
		position: absolute;
		top: 1.3125rem;
		max-width: 4.875rem;
		opacity: 0;
		visibility: hidden;
		z-index: -1;
	}
}
@media (max-width: 567px) {
	.col-lang {
		left: 0;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.col-lang {
		left: 2.375rem;
	}
}
.nav-panel {
	background-color: #fff;
	padding-top: 3.9375rem;
	padding-bottom: 3.4375rem;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	-webkit-transform: translateY(-100vh);
	    -ms-transform: translateY(-100vh);
	        transform: translateY(-100vh);
	position: absolute;
	top: 5.75rem;
	left: 0;
	right: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.nav-panel {
		top: 5.6875rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.nav-panel {
		top: 5.1875rem;
		padding-bottom: 6.25rem;
	}
}
@media (max-width: 767px) {
	.nav-panel {
		top: 3.875rem;
		padding-top: 0;
		padding-bottom: 2.8125rem;
	}
}
@media (max-width: 767px) {
	.nav-panel .container {
		max-width: 100%;
	}
}
.nav-panel__text {
	opacity: .3;
	line-height: 1.71;
}
.nav-panel__list {
	margin-bottom: 4.0625rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.nav-panel__list {
		margin-bottom: 2.9375rem;
	}
}
.nav-panel__list.nav-swiped {
	-webkit-transform: translateX(-100vw);
	    -ms-transform: translateX(-100vw);
	        transform: translateX(-100vw);
}
.nav-panel__list li {
	margin-bottom: .75rem;
}
@media (max-width: 767px) {
	.nav-panel__list li {
		margin-bottom: 0;
	}
}
.nav-panel__list li a {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.nav-panel__list li a:after {
	content: '';
	display: block;
	position: absolute;
	height: 1px;
	right: 0;
	left: 0;
	background-color: #000;
	top: .75rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	width: 0;
}
@media (max-width: 767px) {
	.nav-panel__list li a {
		height: 3rem;
		border-bottom: .0625rem solid rgba(204,204,204,.5);
	}
	.nav-panel__list li a:before, .nav-panel__list li a:after {
		width: .4375rem;
		height: .0625rem;
		background-color: #000;
		right: .625rem;
		left: auto;
	}
	.nav-panel__list li a:before {
		content: '';
		display: block;
		position: absolute;
		-webkit-transform: rotate(45deg);
		    -ms-transform: rotate(45deg);
		        transform: rotate(45deg);
		top: 1.1875rem;
	}
	.nav-panel__list li a:after {
		-webkit-transform: rotate(-45deg);
		    -ms-transform: rotate(-45deg);
		        transform: rotate(-45deg);
		bottom: 1.375rem;
		top: auto;
	}
}
.nav-panel__list li a.nav-panel__list__direct:after {
	display: none;
}
@media (max-width: 767px) {
	.nav-panel__list li a.nav-panel__list__direct:before {
		display: none;
	}
}
.nav-panel__list li a.nav-panel__list__direct svg {
	opacity: 0;
	margin-top: -.125rem;
	margin-left: 0;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	-webkit-transform: translateX(-.625rem);
	    -ms-transform: translateX(-.625rem);
	        transform: translateX(-.625rem);
	position: relative;
	z-index: 12;
	width: 1.875rem;
	height: auto;
}
.nav-panel__list li a.nav-panel__list__direct svg g {
	fill: rgba(0,0,0,.5);
}
.nav-panel__list li a.nav-panel__list__direct:hover .nav-panel__list_text {
	color: #000;
}
.nav-panel__list li a.nav-panel__list__direct:hover svg {
	opacity: 1;
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
}
@media (min-width: 768px) {
	.nav-panel__list li a.nav-hovered {
		color: #000;
	}
	.nav-panel__list li a.nav-hovered:after {
		width: calc(100% - 1.75rem);
	}
	.nav-panel__list li a.nav-hovered .nav-panel__list_text {
		color: #000;
	}
}
.nav-panel__list_text {
	display: inline-block;
	background-color: #fff;
	color: rgba(0,0,0,.5);
	position: relative;
	z-index: 10;
	font-size: 1em;
	font-weight: bold;
	line-height: 1.63;
	padding-right: 2.4375rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.nav-panel__list_text {
		color: #000;
	}
}
.nav-panel__sublist {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.nav-panel__sublist {
		position: fixed;
		top: 3.875rem;
		opacity: 1;
		visibility: visible;
		z-index: 10;
		-webkit-transform: translateX(100vw);
		    -ms-transform: translateX(100vw);
		        transform: translateX(100vw);
	}
}
@media (max-width: 567px) {
	.nav-panel__sublist {
		left: 2.125rem;
		right: 2.125rem;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.nav-panel__sublist {
		left: 3.8125rem;
		right: 3.8125rem;
	}
}
.nav-panel__sublist.nav-sub-swiped {
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
}
.nav-panel__sublist.nav-sub-visible {
	opacity: 1;
	visibility: visible;
	z-index: 10;
}
.nav-panel__sublist li {
	margin-bottom: .125rem;
}
.nav-panel__sublist li.nav-panel__sublist_back {
	display: none;
}
@media (max-width: 767px) {
	.nav-panel__sublist li.nav-panel__sublist_back {
		display: block;
	}
}
.nav-panel__sublist li.nav-panel__sublist_back a {
	color: #b2b2b2;
	padding-left: 1.5625rem;
}
.nav-panel__sublist li.nav-panel__sublist_back a:before, .nav-panel__sublist li.nav-panel__sublist_back a:after {
	right: auto;
	left: 0;
	display: block;
}
.nav-panel__sublist li.nav-panel__sublist_back a:before {
	-webkit-transform: rotate(-45deg);
	    -ms-transform: rotate(-45deg);
	        transform: rotate(-45deg);
}
.nav-panel__sublist li.nav-panel__sublist_back a:after {
	-webkit-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
}
.nav-panel__sublist li a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: rgba(0,0,0,.5);
	font-size: .8125em;
	line-height: 2.15;
}
@media (max-width: 767px) {
	.nav-panel__sublist li a {
		color: #000;
		line-height: 1.47;
		font-size: .9375em;
		height: 3rem;
		border-bottom: .0625rem solid rgba(204,204,204,.5);
	}
	.nav-panel__sublist li a:before, .nav-panel__sublist li a:after {
		content: '';
		display: block;
		position: absolute;
		width: .4375rem;
		height: .0625rem;
		background-color: #000;
		right: .625rem;
		left: auto;
		display: none;
	}
	.nav-panel__sublist li a:before {
		-webkit-transform: rotate(45deg);
		    -ms-transform: rotate(45deg);
		        transform: rotate(45deg);
		top: 1.25rem;
	}
	.nav-panel__sublist li a:after {
		-webkit-transform: rotate(-45deg);
		    -ms-transform: rotate(-45deg);
		        transform: rotate(-45deg);
		bottom: 1.375rem;
		top: auto;
	}
}
.nav-panel__sublist li a:hover {
	color: #000;
}
.nav-panel__sublist li a:hover svg {
	opacity: 1;
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
}
.nav-panel__sublist li a svg {
	opacity: 0;
	margin-top: -.125rem;
	margin-left: 1.1875rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	width: 1.875rem;
	height: auto;
	-webkit-transform: translateX(-.625rem);
	    -ms-transform: translateX(-.625rem);
	        transform: translateX(-.625rem);
}
.nav-panel__sublist li a svg g {
	fill: rgba(0,0,0,.5);
}
@media (max-width: 767px) {
	body.open, html.open {
		overflow: hidden;
	}
}
body.open .nav-back {
	opacity: .25;
	visibility: visible;
}
@media (max-width: 767px) {
	body.open .nav-logo {
		opacity: 0;
		visibility: hidden;
		z-index: -1;
		pointer-events: none;
	}
}
@media (max-width: 767px) {
	body.open .col-lang {
		visibility: visible;
		opacity: 1;
		z-index: 9999;
	}
}
body.open .nav-blind_line {
	opacity: 0;
}
body.open .nav-panel {
	opacity: 1;
	visibility: visible;
	z-index: 10;
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
}
@media (min-width: 768px) and (max-width: 812px) and (orientation: landscape) {
	body.open .nav-panel {
		height: 100vh;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
}
@media (max-width: 767px) {
	body.open .nav-panel {
		height: 100vh;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
}
body.open .nav-icon span:nth-child(1) {
	top: 1.125rem;
	width: 0;
	left: 50%;
}
body.open .nav-icon span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
}
body.open .nav-icon span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	    -ms-transform: rotate(-45deg);
	        transform: rotate(-45deg);
}
body.open .nav-icon span:nth-child(4) {
	top: 1.125rem;
	width: 0;
	left: 50%;
}
body.open .nav-menu_text.open {
	display: none;
}
body.open .nav-menu_text.close {
	display: block;
}
@media (min-width: 768px) {
	body.open .nav-menu:hover .nav-icon span:nth-child(2) {
		-webkit-transform: rotate(35deg);
		    -ms-transform: rotate(35deg);
		        transform: rotate(35deg);
	}
	body.open .nav-menu:hover .nav-icon span:nth-child(3) {
		-webkit-transform: rotate(-35deg);
		    -ms-transform: rotate(-35deg);
		        transform: rotate(-35deg);
	}
}
body.open nav.unscroll {
	background-color: #fff;
}
body.open nav.unscroll .nav-lang__list:before {
	background-color: #000;
}
body.open nav.unscroll.nav-index .nav-menu_text {
	color: #000;
}
body.open nav.unscroll.nav-index .nav-container {
	border-color: #000;
}
body.open nav.unscroll.nav-index .nav-icon span {
	background-color: #000;
}
body.open nav.unscroll.nav-index .nav-lang_link {
	color: #000;
}
body.open nav.unscroll.nav-index .nav-lang:after {
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-left: .25rem solid transparent;
	border-right: .25rem solid transparent;
	border-top: .25rem solid #000;
}
body.open nav.unscroll.nav-index .nav-container:after {
	background-color: #000;
}
body.open nav.unscroll.nav-blind .nav-container:after {
	background-color: #000;
}
@media (max-width: 991px) {
	body.open nav.unscroll.nav-blind .nav-menu_text {
		color: #000;
	}
}
@media (max-width: 991px) {
	body.open nav.unscroll.nav-blind .nav-icon span {
		background-color: #000;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	body.open nav.unscroll.nav-blind .nav-lang:after {
		content: '';
		display: block;
		position: absolute;
		width: 0;
		height: 0;
		border-left: .25rem solid transparent;
		border-right: .25rem solid transparent;
		border-top: .25rem solid #000;
	}
	body.open nav.unscroll.nav-blind .nav-lang_link {
		color: #000;
	}
	body.open nav.unscroll.nav-blind .nav-container {
		border-color: #000;
	}
}
body.open nav.unscroll.nav-404 .nav-container {
	border-color: #000;
}
body.open nav.unscroll.nav-404 .nav-icon span {
	background-color: #000;
}
body.open nav.unscroll.nav-404 .nav-lang:after {
	border-top-color: #000;
}
body.open nav.unscroll.nav-404 .nav-menu_text, body.open nav.unscroll.nav-404 .nav-lang_link {
	color: #000;
}
.nav-region {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: .5rem;
}
@media (max-width: 767px) {
	.nav-region {
		margin-bottom: 6.6875rem;
	}
}
.nav-region__select {
	max-width: 12.5rem;
	position: relative;
}
.nav-region__select:before {
	content: '';
	display: block;
	position: absolute;
	bottom: -1.125rem;
	left: 0;
	right: 0;
	height: .0625rem;
	background-color: #ccc;
}
.nav-region__select .nav-lang__list {
	padding-top: 2.5625rem;
}
.nav-region__select .nav-lang__list:before {
	top: 2.5625rem;
}
@media (min-width: 1600px) {
	.nav-region__select .nav-lang__list:before {
		top: 2.3125rem;
	}
}
.nav-region_text {
	font-size: .875em;
	color: rgba(0,0,0,.3);
	margin-right: 3.0625rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.nav-region_text {
		margin-right: 1.5625rem;
	}
}
.nav-region .nav-lang_link {
	padding: 0;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	max-width: 12.5rem;
	width: 100%;
}
.nav-region-link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 12.5rem;
	width: 100%;
	-webkit-box-pack: start !important;
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
	text-transform: none !important;
	padding-left: 1.375rem;
}
.nav-region-link:hover {
	background-color: #000 !important;
}
.nav-region-link:hover .nav-region-link__text {
	color: #fff;
}
.nav-region-link__icon {
	margin-right: 1.375rem;
}
section {
	padding-top: 12.5rem;
	padding-bottom: 10.9375rem;
}
@media (max-width: 767px) {
	section {
		padding-top: 9.3125rem;
		padding-bottom: 14.5625rem;
	}
}
main {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	address {
		margin-bottom: 5.375rem;
	}
}
.section-gray {
	background-color: #f4f4f5;
	padding-top: 5.5rem;
}
@media (max-width: 767px) {
	.section-gray {
		padding-top: 5rem;
	}
}
.section-img {
	padding-top: 0;
	padding-bottom: 0;
}
.section-slider-index {
	padding-bottom: 3.5rem;
}
@media (max-width: 767px) {
	.section-slider-index {
		padding-bottom: 9rem;
	}
}
.section-slider-index .link-more {
	margin-top: -4.4375rem;
	position: relative;
	z-index: 6000;
}
@media (max-width: 767px) {
	.section-last {
		padding-bottom: 5.625rem;
	}
}
.section-dream {
	padding-bottom: 15.1875rem;
	counter-reset: dream-list;
}
@media (max-width: 767px) {
	.section-dream {
		padding-bottom: 10.9375rem;
	}
}
.section-factory {
	padding-bottom: 6.875rem;
}
@media (max-width: 767px) {
	.section-factory {
		padding-bottom: 10.625rem;
	}
}
.section-top {
	padding-top: 0;
}
@media (max-width: 767px) {
	.section-top {
		padding-bottom: 5.625rem;
	}
}
.section-offspring {
	padding-bottom: 0;
}
@media (max-width: 767px) {
	.section-offspring {
		margin-top: 4.625rem;
	}
}
.section-offspring-last {
	padding-top: 8.125rem;
	padding-bottom: 15.6875rem;
}
@media (max-width: 767px) {
	.section-offspring-last {
		padding-bottom: 10.625rem;
	}
}
.section-together {
	padding-bottom: 11.25rem;
	padding-top: 0;
}
@media (max-width: 767px) {
	.section-together {
		padding-bottom: 9.625rem;
	}
}
.section-together-start {
	padding-top: 12.5rem;
	padding-bottom: 7.75rem;
}
@media (max-width: 767px) {
	.section-together-start {
		padding-top: 9.3125rem;
		padding-bottom: 5.875rem;
	}
}
.section-contact-press {
	margin-bottom: 4.6875rem;
}
.section-bank {
	margin-bottom: 3.4375rem;
}
@media (max-width: 767px) {
	.section-bank {
		margin-bottom: 0;
	}
}
.section-map {
	padding-top: 0;
	position: relative;
	padding-bottom: 15.375rem;
}
@media (max-width: 767px) {
	.section-map {
		padding-bottom: 9.125rem;
	}
}
.section-map:after {
	content: '';
	display: block;
	position: absolute;
	z-index: -1;
	background-color: #f4f4f5;
	bottom: 0;
	left: 0;
	right: 0;
	height: 26.5625rem;
}
.section-inner-last {
	padding-top: 6.75rem;
	padding-bottom: 6.125rem;
}
@media (max-width: 767px) {
	.section-inner-last {
		padding-top: 6.1875rem;
	}
}
.section-inner-ethic-last {
	padding-bottom: 11.25rem;
}
.section-beer-first {
	padding-bottom: 12.5rem;
}
@media (max-width: 767px) {
	.section-beer-first {
		padding-bottom: 7.0625rem;
	}
	.section-beer-first img {
		margin-bottom: 3.75rem;
	}
}
.section-beer {
	padding-top: 0;
	padding-bottom: 6.6875rem;
}
@media (max-width: 767px) {
	.section-beer {
		padding-bottom: 0;
	}
	.section-beer img {
		margin-bottom: 3.75rem;
	}
}
@media (max-width: 767px) {
	.section-beer h3 {
		margin-top: 2.5rem;
	}
}
@media (max-width: 767px) {
	.section-beer h2 {
		margin-top: 8.4375rem;
	}
}
.section-beer ul li, .section-beer ol li {
	font-size: .875em;
	line-height: 1.86;
	position: relative;
}
.section-beer ul li {
	padding-left: 1.375rem;
}
.section-beer ul li:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: .625rem;
	width: .625rem;
	height: .1875rem;
	background-color: #000;
}
.section-beer-last {
	padding-bottom: 11.125rem;
}
@media (max-width: 767px) {
	.section-beer-last img {
		margin-bottom: 3.75rem;
	}
}
.bg-gray {
	background-color: #f4f4f5;
}
@media (max-width: 767px) {
	.spacer-mob-47 {
		height: 2.9375rem;
	}
}
.spacer-74 {
	height: 4.625rem;
}
.spacer-123 {
	height: 7.6875rem;
}
@media (max-width: 767px) {
	.spacer-mob-50 {
		height: 3.125rem;
	}
}
.row-shareholders {
	padding-top: 8.3125rem;
}
.section-alcohol {
	padding-bottom: 5.75rem;
}
@media (max-width: 767px) {
	.section-alcohol {
		padding-top: 2.625rem;
	}
}
.section-dream-alcohol {
	padding-bottom: .625rem;
}
.section-dream-alcohol .article-regular__container {
	padding-bottom: 9.4375rem;
}
.section-404 {
	padding-bottom: 19.25rem;
}
@media (max-width: 767px) {
	.section-404 {
		padding-bottom: 6.25rem;
	}
}
@media (min-width: 1200px) {
	.section-press {
		padding-bottom: 3.375rem;
	}
}
@media (max-width: 767px) {
	.section-career {
		padding-bottom: 5.5rem;
	}
}
@media (max-width: 767px) {
	.section-internship {
		padding-bottom: 8.75rem;
	}
}
.iframe-full-height {
	max-width: 100%;
	width: 100%;
	position: relative;
	z-index: 300;
	height: 100%;
	min-height: 76.875rem;
}
@media (max-width: 767px) {
	.iframe-full-height {
		min-height: 96.875rem;
	}
}
.breadcrumbs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 6.9375rem;
	position: relative;
	z-index: 100;
	margin-top: 6.25rem;
}
@media (max-width: 767px) {
	.breadcrumbs {
		margin-top: 4.375rem;
		margin-bottom: 3.625rem;
	}
}
.breadcrumbs li {
	position: relative;
	padding-right: .9375rem;
	margin-right: .4375rem;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
	font-size: .75em;
	line-height: 2.17;
	color: rgba(0,0,0,.4);
}
@media (max-width: 767px) {
	.breadcrumbs li {
		padding-right: .75rem;
		margin-right: .125rem;
	}
}
.breadcrumbs li:after {
	content: '';
	display: block;
	position: absolute;
	right: .25rem;
	top: .25rem;
	height: 1rem;
	width: .0625rem;
	background-color: rgba(0,0,0,.4);
	-webkit-transform: rotate(20deg);
	    -ms-transform: rotate(20deg);
	        transform: rotate(20deg);
}
@media (max-width: 767px) {
	.breadcrumbs li:after {
		height: .625rem;
		top: .5rem;
	}
}
.breadcrumbs li:last-child:after {
	display: none;
}
.breadcrumbs li a {
	color: rgba(0,0,0,.4);
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
}
.breadcrumbs li a:hover {
	color: #000;
	text-decoration: underline;
}
.pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 3.125rem auto 3.75rem;
}
@media (max-width: 767px) {
	.pagination {
		margin-top: 0;
		margin-bottom: 5.0625rem;
	}
}
.pagination li {
	margin: 0 1.0625rem;
}
@media (max-width: 767px) {
	.pagination li {
		margin: 0 .5625rem;
	}
}
.pagination li a, .pagination li .page-numbers {
	display: inline-block;
	color: #000;
	font-size: 1.125em;
	line-height: 1;
	font-weight: 700;
	color: #b2b2b2;
}
.pagination li a:hover, .pagination li .page-numbers:hover {
	color: #000;
}
@media (max-width: 767px) {
	.pagination li.arrow {
		margin-top: -.3125rem;
	}
}
.pagination li.arrow.left {
	margin-right: 3.125rem;
}
@media (max-width: 767px) {
	.pagination li.arrow.left {
		margin-right: 1.5625rem;
	}
}
.pagination li.arrow.right {
	margin-left: 3.125rem;
}
@media (max-width: 767px) {
	.pagination li.arrow.right {
		margin-left: 1.5625rem;
	}
}
.pagination li.arrow a {
	position: relative;
	width: 1.625rem;
	height: 1.625rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-bottom: .0625rem;
	z-index: 10;
}
@media (max-width: 767px) {
	.pagination li.arrow a {
		width: 2.5rem;
		height: 2.5rem;
	}
}
.pagination li.arrow a svg path {
	fill: #000;
}
.pagination li.arrow a:after {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	content: '';
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border: .0625rem solid #000;
	border-radius: 50%;
	z-index: -1;
}
.pagination li.arrow a:hover svg path {
	fill: #fff;
}
.pagination li.arrow a:hover:after {
	left: -.25rem;
	right: -.25rem;
	top: -.25rem;
	bottom: -.25rem;
	background-color: #000;
}
.pagination li .active, .pagination li .current {
	cursor: default;
	pointer-events: none;
	font-size: 1.5em;
	position: relative;
	color: #000;
}
.pagination li .active:after, .pagination li .current:after {
	content: '';
	display: block;
	position: absolute;
	border-bottom: .125rem solid #000;
	bottom: 0;
	left: 0;
	right: 0;
}
#lottie {
	width: 100%;
	height: 100%;
	display: block;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0);
	text-align: center;
	opacity: 1;
	background-color: #f4f4f5;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
#lottie svg {
	width: 8.125rem !important;
	height: 8.125rem !important;
}
button {
	border: none;
	cursor: pointer;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
button:focus {
	outline: none;
}
.btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 3rem;
	max-width: 15.5625rem;
	width: 100%;
	padding: .0625rem .9375rem 0;
	font-size: .875em;
	line-height: 1.86;
	font-weight: 700;
	color: #fff;
	background-color: #000;
}
@media (max-width: 767px) {
	.btn {
		height: 3.25rem;
		font-size: 1em;
		padding-bottom: .0625rem;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.btn {
		max-width: 20rem;
	}
}
@media (max-width: 567px) {
	.btn {
		max-width: 100%;
	}
}
.btn:hover {
	color: rgba(255,255,255,.6);
}
.btn-radius {
	border-radius: 2em;
}
.btn-shadow {
	-webkit-box-shadow: 0 5px 17px 0 rgba(0,0,0,.26);
	        box-shadow: 0 5px 17px 0 rgba(0,0,0,.26);
}
.btn-white {
	background-color: #fff;
	color: #000;
	font-size: 1em;
	font-weight: 500;
	border: 2px solid transparent;
}
.btn-white:hover {
	border: 2px solid #fff;
	color: #fff;
	background-color: transparent;
}
.btn-reset {
	font-size: .875em;
	background-color: #fff;
	color: rgba(0,0,0,.3);
	text-decoration: underline;
	height: auto;
	font-weight: 400;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.btn-reset:hover {
	text-decoration: none;
	color: #000;
}
.btn-filter {
	border: .0625rem solid #000;
	background-color: #fff;
	color: #000;
	height: 3.5rem !important;
	display: none;
}
@media (max-width: 767px) {
	.btn-filter {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-top: 1.9375rem;
		margin-bottom: 1.4375rem;
	}
}
.btn-filter:hover {
	color: #000;
}
.btn-center {
	margin-left: auto;
	margin-right: auto;
}
.btn-text-center {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.btn-blind {
	font-size: .875em;
	font-weight: 700;
	line-height: 1.86;
	color: #ba0c2f;
	margin-top: 2.5rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.btn-blind {
		margin-bottom: 3.125rem;
	}
}
@media (max-width: 767px) {
	.btn-blind {
		font-size: 1em;
		height: 3.5rem;
		margin-bottom: 5.625rem;
	}
}
.btn-sm {
	max-width: 12.4375rem;
}
@media (max-width: 567px) {
	.btn-sm {
		max-width: 100%;
	}
}
.btn-red {
	background-color: #ba0c2f;
	color: #fff;
}
.btn-disabled {
	pointer-events: none;
	cursor: none;
	opacity: .4;
}
.btn-country {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
	border: .0625rem solid #e8e8e8;
}
.btn-country__icon {
	margin-right: 1rem;
}
.btn-country_text {
	color: #000;
	line-height: .7;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.btn-country_text {
		font-size: .9375em;
	}
}
.btn-country:hover .btn-country_text {
	text-decoration: underline;
}
.btn-country.checked .btn-country_text {
	color: rgba(0,0,0,.4);
}
.btn-country.active {
	border-color: #000;
}
.btn-country.active .btn-country_text {
	color: #000;
}
.btn-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	max-width: 18.75rem;
	height: 3.125rem;
}
@media (max-width: 767px) {
	.btn-icon {
		height: 3.5rem;
	}
}
.btn-icon__icon {
	width: 1.625rem;
	height: 1.75rem;
	margin-left: 1.875rem;
}
.btn-icon__icon svg {
	width: 1.625rem;
	height: auto;
}
.btn-icon__icon svg path, .btn-icon__icon svg circle {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.btn-icon:hover .btn-icon__icon path {
	fill: #ba0c2f;
}
.btn-icon:hover .btn-icon__icon circle {
	fill: #fff;
}
.btn-internship-top {
	position: absolute;
	max-width: 12.5rem;
	top: -6.5625rem;
	right: -6.25rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.btn-internship-top {
		right: -5rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.btn-internship-top {
		right: 0;
	}
}
@media (max-width: 767px) {
	.btn-internship-top {
		position: static;
		max-width: 100%;
		height: 3.5rem;
		margin-bottom: 1.625rem;
	}
}
.btn-internship {
	margin-top: 6.875rem;
	margin-bottom: 1.5625rem;
}
.btn-red {
	background-color: #ba0c2f;
}
.btn-pair {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
.btn-pair .btn {
	margin-right: 3.125rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.btn-pair .btn {
		margin-bottom: .9375rem;
	}
}
@media (max-width: 767px) {
	.btn-pair .btn {
		margin-bottom: .9375rem;
		margin-right: 0;
	}
}
@media (max-width: 767px) {
	.btn-pair form {
		width: 100%;
	}
}
.img-responsive {
	width: 100%;
}
.img-fit {
	-o-object-fit: cover;
	   object-fit: cover;
}
.img-center {
	margin-left: auto;
	margin-right: auto;
	display: block;
	margin-bottom: .8125rem;
}
.img-index {
	-o-object-fit: cover;
	   object-fit: cover;
	min-height: 8.125rem;
}
.img-pair {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 5.6875rem;
}
@media (max-width: 767px) {
	.img-pair {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		margin-bottom: 1.6875rem;
	}
}
.img-pair div {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 calc(50% - 1.5625rem);
	    flex: 0 0 calc(50% - 1.5625rem);
	max-width: calc(50% - 1.5625rem);
}
@media (min-width: 768px) and (max-width: 991px) {
	.img-pair div {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 calc(50% - .9375rem);
		    flex: 0 0 calc(50% - .9375rem);
		max-width: calc(50% - .9375rem);
	}
}
@media (max-width: 767px) {
	.img-pair div {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 1.6875rem;
	}
}
.img-pair img {
	-o-object-fit: cover;
	   object-fit: cover;
}
@media (max-width: 767px) {
	.img-pair img {
		width: 100%;
	}
}
.img-pair-index img {
	height: 15.625rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.img-pair-index img {
		max-width: 98%;
	}
}
.img-logo {
	width: 100%;
	max-width: 15.5625rem;
}
.img-logo-big {
	width: 100%;
	margin-top: 4.625rem;
	max-width: 47rem;
}
@media (min-width: 1200px) {
	.img-logo-big {
		max-width: 100%;
		float: left;
	}
}
@media (max-width: 767px) {
	.img-logo-big {
		display: none;
	}
}
.img-logo-md {
	width: 100%;
	max-width: 28.1875rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.img-logo-md {
		max-width: 18.75rem;
		margin-top: .4375rem;
	}
}
@media (max-width: 767px) {
	.img-logo-md {
		display: none;
	}
}
.img-logo-md.is-right {
	float: right;
}
.img-dream1 {
	margin-bottom: 6.75rem;
}
@media (max-width: 767px) {
	.img-dream1 {
		margin-top: 2.8125rem;
		margin-bottom: 6.25rem;
	}
}
.img-dream2 {
	margin-top: 5.125rem;
}
@media (max-width: 767px) {
	.img-dream2 {
		margin-top: 0;
		margin-bottom: 7.1875rem;
	}
}
.img-dream3 {
	margin-top: 5.125rem;
}
@media (max-width: 767px) {
	.img-dream3 {
		margin-top: 5.5rem;
		margin-bottom: 6.125rem;
	}
}
.img-whoare {
	margin-bottom: 4.4375rem;
}
@media (max-width: 767px) {
	.img-whoare {
		min-height: 8.125rem;
		-o-object-fit: cover;
		   object-fit: cover;
		margin-bottom: 3.4375rem;
	}
}
@media (max-width: 767px) {
	.img-responsibility {
		margin-bottom: 3.1875rem;
	}
}
.img-big {
	margin-top: 7.75rem;
	margin-bottom: 11.125rem;
}
@media (max-width: 767px) {
	.img-together {
		height: 12.5rem;
		-o-object-fit: cover;
		   object-fit: cover;
	}
}
.img-logo-line-big {
	max-width: 48.4375rem;
	float: right;
	width: 100%;
}
@media (max-width: 767px) {
	.img-logo-line-big {
		display: none;
	}
}
.img-logo-rotate {
	max-width: 5rem;
	width: 100%;
}
.img-beer-1 {
	float: right;
	margin-bottom: 5rem;
	width: 100%;
	max-width: 28.125rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.img-beer-1 {
		float: none;
	}
}
@media (max-width: 767px) {
	.img-beer-1 {
		float: none;
		max-width: 11.5625rem;
		margin-top: 5.875rem;
		margin-bottom: 6.1875rem;
	}
}
.img-beer-5 {
	width: 100%;
	max-width: 28.125rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.img-beer-5 {
		float: none;
	}
}
@media (max-width: 767px) {
	.img-beer-5 {
		float: none;
		margin-bottom: 7.3125rem !important;
		margin-top: 4.375rem;
	}
}
.img-beer-2 {
	display: inline-block;
	width: 100%;
	max-width: 21.8125rem;
	margin-top: 10.625rem;
}
@media (max-width: 767px) {
	.img-beer-2 {
		margin-top: 4.375rem;
	}
}
.img-beer-3 {
	display: inline-block;
	width: 100%;
	margin-top: 6.6875rem;
	margin-bottom: -11.25rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.img-beer-3 {
		margin-bottom: 1.875rem;
	}
}
@media (max-width: 767px) {
	.img-beer-3 {
		margin-top: 4.375rem;
		margin-bottom: 0rem !important;
	}
}
.img-beer-4 {
	display: inline-block;
	width: 100%;
	margin-top: 4.375rem;
}
@media (max-width: 767px) {
	.img-beer-4 {
		max-width: 11.5625rem;
		margin-bottom: 0rem !important;
	}
}
@media (max-width: 767px) {
	.img-beer-6 {
		margin-top: 4.375rem;
		margin-bottom: 0rem !important;
	}
}
.img-icon-big {
	margin-top: -1.875rem;
	margin-bottom: 1.8125rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.img-icon-big {
		margin-top: 0;
	}
}
@media (max-width: 767px) {
	.img-icon-big {
		margin-top: .875rem;
	}
}
.img-alcohol {
	margin-top: 7.8125rem;
	display: inline-block;
}
@media (max-width: 767px) {
	.img-alcohol {
		display: none;
		margin-top: 0;
	}
}
.img-404 {
	position: absolute;
	display: inline-block;
	top: -11.25rem;
	left: 0;
	z-index: 1;
	mix-blend-mode: multiply;
}
@media (max-width: 767px) {
	.img-404 {
		display: none;
	}
}
a {
	text-decoration: none;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
a svg g {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
a:hover {
	text-decoration: none;
}
a:focus {
	text-decoration: none;
	outline: none;
}
.link-more {
	position: relative;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	z-index: 10;
}
@media (max-width: 767px) {
	.link-more {
		margin-top: 2.1875rem;
	}
}
.link-more__angle {
	padding-top: .0625rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 1.625rem;
	height: 1.625rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	position: relative;
}
.link-more__angle:before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: transparent;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	border-radius: 50%;
	border: .0625rem solid #000;
	z-index: -1;
}
@media (max-width: 767px) {
	.link-more__angle {
		width: 1.9375rem;
		height: 1.9375rem;
	}
}
.link-more__angle svg {
	width: .75rem;
	height: auto;
}
.link-more__angle svg g {
	-webkit-transition: none;
	     -o-transition: none;
	        transition: none;
}
.link-more_text {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.link-more.down {
	display: inline-block;
}
.link-more.down .link-more_text {
	font-size: 1.125em;
	letter-spacing: .0625rem;
	color: #fff;
	margin-bottom: 1.25rem;
}
@media (max-width: 767px) {
	.link-more.down .link-more_text {
		margin-bottom: .9375rem;
		font-size: 1em;
	}
}
.link-more.down .link-more__angle:before {
	border-color: #fff;
}
.link-more.down:hover .link-more_text {
	opacity: .5;
}
.link-more.down:hover .link-more__angle:before {
	background-color: #fff;
}
.link-more.down:hover .link-more__angle svg g {
	fill: #000;
}
.link-more.right {
	padding-right: 3.125rem;
}
@media (max-width: 767px) {
	.link-more.right {
		padding-right: 3.4375rem;
	}
}
.link-more.right:hover .link-more__angle:before {
	left: -.1875rem;
	right: -.1875rem;
	bottom: -.1875rem;
	top: -.1875rem;
}
.link-more.right:hover .link-more__angle:after {
	left: -4.5rem;
	right: -4.5rem;
	bottom: -4.5rem;
	top: -4.5rem;
}
.link-more.right:hover .link-more_text {
	color: #000;
}
.link-more.right .link-more_text {
	font-size: .9375em;
	font-weight: 700;
}
@media (max-width: 767px) {
	.link-more.right .link-more_text {
		font-size: 1em;
	}
}
.link-more.right .link-more__angle {
	-webkit-transform: rotate(-90deg);
	    -ms-transform: rotate(-90deg);
	        transform: rotate(-90deg);
	position: absolute;
	right: 0;
	top: calc(50% - .875rem);
}
@media (max-width: 767px) {
	.link-more.right .link-more__angle {
		top: calc(50% - 1rem);
	}
}
.link-more.right .link-more__angle:before {
	background-color: #000;
}
.link-more.right .link-more__angle:after {
	content: '';
	display: block;
	position: absolute;
	left: -5.0625rem;
	right: -5.0625rem;
	bottom: -5.0625rem;
	top: -5.0625rem;
	border-radius: 50%;
	border: .0625rem solid #000;
	z-index: -1;
	opacity: .2;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.link-more.right .link-more__angle:after {
		left: -5rem;
		right: -5rem;
		bottom: -5rem;
		top: -5rem;
	}
}
.link-more.right.link-404 {
	margin-top: 1.875rem;
}
.link-more.right.link-404 .link-more_text {
	color: #fff;
	text-decoration: underline;
}
.link-more.right.link-404 .link-more__angle:before {
	border: .0625rem solid #fff;
	background-color: transparent;
}
.link-more.right.link-404:hover .link-more__angle:before {
	background-color: #fff;
}
.link-more.right.link-404:hover .link-more__angle svg path {
	fill: #ba0c2f;
}
.link-more.right.is-right {
	float: right;
}
@media (max-width: 767px) {
	.link-more.right.is-right {
		float: none;
	}
}
.link-more.right.small:hover .link-more__angle:after {
	left: -3.25rem;
	right: -3.25rem;
	bottom: -3.25rem;
	top: -3.25rem;
}
.link-more.right.small .link-more__angle:after {
	left: -4.5rem;
	right: -4.5rem;
	bottom: -4.5rem;
	top: -4.5rem;
	opacity: .1;
}
.link-more.plus:hover .link-more__angle {
	-webkit-transform: rotate(90deg);
	    -ms-transform: rotate(90deg);
	        transform: rotate(90deg);
}
.link-more.plus:hover .link-more__angle:before {
	left: -.25rem;
	right: -.25rem;
	bottom: -.25rem;
	top: -.25rem;
	background-color: #000;
}
.link-more.plus:hover .link-more__angle svg g {
	fill: #fff;
}
.link-more.plus .link-more__angle {
	padding-top: 0;
}
.link-more.plus .link-more__angle:before {
	background-color: transparent;
	border: .0625rem solid #000;
}
.link-more.plus .link-more__angle:after {
	display: none;
}
.link-more.arrow .link-more__angle {
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
	padding-bottom: .0625rem;
}
.link-more.arrow .link-more__angle svg {
	width: .75rem;
}
.link-more.arrow:hover .link-more__angle {
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
}
.link-more.left {
	padding-left: 3.125rem;
}
@media (max-width: 767px) {
	.link-more.left {
		margin-top: 2.75rem;
		padding-left: 3.4375rem;
	}
}
.link-more.left:hover .link-more__angle:before {
	left: -.1875rem;
	right: -.1875rem;
	bottom: -.1875rem;
	top: -.1875rem;
}
.link-more.left:hover .link-more_text {
	color: #000;
}
.link-more.left .link-more_text {
	font-size: .9375em;
	font-weight: 700;
}
@media (max-width: 767px) {
	.link-more.left .link-more_text {
		font-size: 1em;
	}
}
.link-more.left .link-more__angle {
	-webkit-transform: rotate(90deg);
	    -ms-transform: rotate(90deg);
	        transform: rotate(90deg);
	position: absolute;
	left: 0;
	top: calc(50% - .875rem);
}
@media (max-width: 767px) {
	.link-more.left .link-more__angle {
		top: calc(50% - 1rem);
	}
}
.link-more.left .link-more__angle:before {
	background-color: #000;
}
.link-back {
	display: none;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: .9375em;
	height: 2.9375rem;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	border-bottom: .0625rem solid rgba(71,92,142,.6);
	color: rgba(71,92,142,.6);
	padding-left: 1.5625rem;
	-webkit-transform: translateX(100vw);
	    -ms-transform: translateX(100vw);
	        transform: translateX(100vw);
}
.link-back:after, .link-back:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	width: .4375rem;
	height: .0625rem;
	background-color: #2d4274;
	opacity: 1;
}
.link-back:before {
	-webkit-transform: rotate(-45deg);
	    -ms-transform: rotate(-45deg);
	        transform: rotate(-45deg);
	bottom: 1.5625rem;
	top: auto;
}
.link-back:after {
	-webkit-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
	bottom: auto;
	top: 1.5625rem;
}
.link-back.swipe {
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
}
@media (max-width: 767px) {
	.link-back {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}
ul, ol {
	padding: 0;
	margin: 0;
}
ul li, ol li {
	list-style: none;
	position: relative;
}
ol {
	counter-reset: list1;
}
ol li {
	padding-left: 1.25rem;
}
ol li:before {
	display: block;
	position: absolute;
	counter-increment: list1;
	content: counter(list1);
	left: 0;
	top: 0;
	font-size: 1em;
	font-weight: 500;
	line-height: 2;
	color: #000;
}
ol li:after {
	content: '';
	display: block;
	position: absolute;
	background-color: #000;
	width: .25rem;
	height: .25rem;
	top: .625rem;
	left: .875rem;
}
.ul-regular li {
	padding-left: 1.25rem;
}
.ul-regular li:before {
	content: '';
	display: block;
	position: absolute;
	width: .5rem;
	height: .0625rem;
	background-color: #000;
	top: .625rem;
	left: 0;
}
#map, .contact-map {
	width: 100%;
	height: 22.5rem;
	border: .3125rem solid #fff;
}
.gm-style * {
	width: auto;
	height: auto;
}
.slick-loading .slick-list {
	background: #fff url('../img/slick/ajax-loader.gif') center center no-repeat;
}
@font-face {
	font-family: 'slick';
	font-weight: normal;
	font-style: normal;
	src: url('../fonts/slick/slick.eot');
	src: url('../fonts/slick/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick/slick.woff') format('woff'), url('../fonts/slick/slick.ttf') format('truetype'), url('../fonts/slick/slick.svg#slick') format('svg');
}
.slider-arrow {
	position: absolute;
	width: 3.125rem;
	height: 3.125rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	z-index: 6000;
	cursor: pointer;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.slider-arrow:focus {
	outline: none;
}
@media (min-width: 768px) {
	.slider-arrow:hover:after {
		left: -.3125rem;
		right: -.3125rem;
		bottom: -.3125rem;
		top: -.3125rem;
	}
}
.slider-arrow:after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	border-radius: 50%;
	border: .0625rem solid #000;
	z-index: -1;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.slider-arrow svg {
	width: .5625rem;
	height: auto;
}
.slider-arrow.swiper-button-disabled {
	opacity: .3;
}
@media (min-width: 768px) {
	.slider-arrow.swiper-button-disabled:hover {
		cursor: auto;
	}
	.slider-arrow.swiper-button-disabled:hover:after {
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
	}
}
.swiper-pagination {
	font-size: 4.6875em;
	color: #f4f4f5;
	        text-stroke: .0625rem rgba(0,0,0,.3);
	-webkit-text-stroke: .0625rem rgba(0,0,0,.3);
	font-weight: 700;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 767px) {
	.swiper-pagination {
		width: auto;
		padding: 0;
		font-size: 3.625em;
		bottom: auto;
		top: 4.125rem;
		right: 1.25rem;
		left: auto;
		color: #fff;
	}
}
.swiper-pagination span {
	margin: 0 .3125rem;
	font-size: 2.875rem;
}
@media (max-width: 767px) {
	.swiper-pagination span {
		font-size: 2rem;
		margin: 0 .1875rem;
	}
}
.container-swiper {
	max-width: 80rem;
	margin-top: -1.25rem;
	position: relative;
}
@media (min-width: 1400px) {
	.container-swiper {
		max-width: 87.5rem;
		margin-top: -6.125rem;
	}
}
.container-swiper:before, .container-swiper:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 100;
}
.container-swiper:before {
	width: 3.75rem;
	left: 0;
	background: -webkit-gradient(linear, left top, right top, from(#f4f4f5), to(rgba(244,244,245,0)));
	background: -webkit-linear-gradient(left, #f4f4f5 0%, rgba(244,244,245,0) 100%);
	background:      -o-linear-gradient(left, #f4f4f5 0%, rgba(244,244,245,0) 100%);
	background:         linear-gradient(to right, #f4f4f5 0%, rgba(244,244,245,0) 100%);
}
@media (max-width: 1199px) {
	.container-swiper:before {
		display: none;
	}
}
.container-swiper:after {
	width: 9.375rem;
	right: 0;
	background: -webkit-gradient(linear, right top, left top, from(#f4f4f5), to(rgba(244,244,245,0)));
	background: -webkit-linear-gradient(right, #f4f4f5 0%, rgba(244,244,245,0) 100%);
	background:      -o-linear-gradient(right, #f4f4f5 0%, rgba(244,244,245,0) 100%);
	background:         linear-gradient(to left, #f4f4f5 0%, rgba(244,244,245,0) 100%);
}
@media (min-width: 992px) and (max-width: 1199px) {
	.container-swiper:after {
		width: 6.25rem;
	}
}
@media (max-width: 991px) {
	.container-swiper:after {
		display: none;
	}
}
.slider-index {
	width: 100%;
	height: 100%;
}
@media (max-width: 767px) {
	.slider-index {
		margin-bottom: 2.75rem;
	}
}
.slider-index:after {
	content: '';
	display: block;
	position: absolute;
	background-color: #fff;
	width: 46.875rem;
	height: 28.125rem;
	top: 4.375rem;
	left: 8.125rem;
}
@media (min-width: 1400px) {
	.slider-index:after {
		left: 12.4375rem;
	}
}
@media (min-width: 768px) and (max-width: 1199px) {
	.slider-index:after {
		left: 1.875rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-index:after {
		width: 44.375rem;
	}
}
@media (max-width: 767px) {
	.slider-index:after {
		display: none;
	}
}
.slider-index .slider-arrow {
	bottom: 2.375rem;
}
@media (max-width: 767px) {
	.slider-index .slider-arrow {
		top: 16.25rem;
		bottom: auto;
	}
}
.slider-index .slider-arrow.prev {
	left: 6.1875rem;
	padding-right: .1875rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-index .slider-arrow.prev {
		left: 1.875rem;
	}
}
@media (max-width: 767px) {
	.slider-index .slider-arrow.prev {
		left: auto;
		right: 6.9375rem;
	}
}
.slider-index .slider-arrow.next {
	left: 34.3125rem;
	padding-left: .1875rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-index .slider-arrow.next {
		left: 30rem;
	}
}
@media (max-width: 767px) {
	.slider-index .slider-arrow.next {
		left: auto;
		right: 1.6875rem;
	}
}
.slider-index .swiper-wrapper {
	margin-left: -27.875rem;
	padding-top: 4.375rem;
	margin-bottom: 7.375rem;
}
@media (max-width: 991px) {
	.slider-index .swiper-wrapper {
		margin-left: 0;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-index .swiper-wrapper {
		margin-bottom: 13.125rem;
	}
}
@media (max-width: 767px) {
	.slider-index .swiper-wrapper {
		padding-top: 2.75rem;
		margin-bottom: .875rem;
	}
}
@media (min-width: 1200px) {
	.slider-index .swiper-wrapper.set-transform {
		-webkit-transform: translate3d(-.75rem, 0, 0) !important;
		        transform: translate3d(-.75rem, 0, 0) !important;
	}
}
.slider-index .swiper-pagination {
	padding-left: 11.875rem;
}
.slider-index .swiper-slide {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	width: 6.875rem !important;
	height: 28.125rem;
}
@media (max-width: 991px) {
	.slider-index .swiper-slide {
		width: 100% !important;
		height: auto;
	}
}
.slider-index .swiper-slide.swiper-slide-active {
	width: 46.875rem !important;
}
@media (max-width: 991px) {
	.slider-index .swiper-slide.swiper-slide-active {
		width: 100% !important;
	}
}
.slider-index .swiper-slide.swiper-slide-active .slide-index__img {
	-webkit-transform: scale(1.52) translateY(-2.625rem);
	    -ms-transform: scale(1.52) translateY(-2.625rem);
	        transform: scale(1.52) translateY(-2.625rem);
	-webkit-box-flex: 0;
	-ms-flex: 0 0 18.75rem;
	    flex: 0 0 18.75rem;
	max-width: 18.75rem;
	margin-left: 3rem;
}
@media (min-width: 1600px) {
	.slider-index .swiper-slide.swiper-slide-active .slide-index__img {
		-webkit-transform: scale(1.7) translateY(-2.625rem);
		    -ms-transform: scale(1.7) translateY(-2.625rem);
		        transform: scale(1.7) translateY(-2.625rem);
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-index .swiper-slide.swiper-slide-active .slide-index__img {
		-webkit-transform: scale(1.15) translateY(-1.375rem);
		    -ms-transform: scale(1.15) translateY(-1.375rem);
		        transform: scale(1.15) translateY(-1.375rem);
		margin-left: 3.4375rem;
	}
}
@media (max-width: 767px) {
	.slider-index .swiper-slide.swiper-slide-active .slide-index__img {
		-webkit-transform: none;
		    -ms-transform: none;
		        transform: none;
		margin-left: 0;
		opacity: 1 !important;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.slider-index .swiper-slide.swiper-slide-active .slide-index__img {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 32%;
		    flex: 0 0 32%;
		max-width: 32%;
	}
}
@media (max-width: 567px) {
	.slider-index .swiper-slide.swiper-slide-active .slide-index__img {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
	}
}
.slider-index .swiper-slide.swiper-slide-active .slide-index__text, .slider-index .swiper-slide.swiper-slide-active .slide-index__flag {
	display: block;
}
@media (min-width: 768px) {
	.slider-index .swiper-slide.swiper-slide-active .slide-index__text, .slider-index .swiper-slide.swiper-slide-active .slide-index__flag {
		-webkit-animation-name: visible-slide;
		        animation-name: visible-slide;
		-webkit-animation-duration: .4s;
		        animation-duration: .4s;
		-webkit-animation-direction: alternate;
		        animation-direction: alternate;
		-webkit-animation-timing-function: ease-out;
		        animation-timing-function: ease-out;
		-webkit-animation-fill-mode: forwards;
		        animation-fill-mode: forwards;
		-webkit-animation-delay: .25s;
		        animation-delay: .25s;
	}
}
@media (max-width: 767px) {
	.slider-index .swiper-slide.swiper-slide-active .slide-index__text, .slider-index .swiper-slide.swiper-slide-active .slide-index__flag {
		opacity: 1 !important;
		-webkit-transform: none;
		    -ms-transform: none;
		        transform: none;
	}
}
.slide-index {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	height: 100%;
}
@media (max-width: 767px) {
	.slide-index {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		background-color: #fff;
		padding: 0 1.6875rem;
	}
}
.slide-index__img {
	-ms-flex-item-align: end;
	align-self: flex-end;
	padding-bottom: 3.5rem;
	text-align: center;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.slide-index__img {
		text-align: left;
		margin-top: -2.125rem;
		padding-bottom: 0;
		height: 18.75rem;
		position: relative;
		opacity: 0;
	}
}
.slide-index__img img {
	max-height: 18.75rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	height: 100%;
}
@media (min-width: 1600px) {
	.slide-index__img img {
		max-height: 16.5625rem;
	}
}
@media (max-width: 767px) {
	.slide-index__img img {
		max-width: 4.625rem;
		height: auto;
		position: absolute;
		bottom: 0;
		left: 0;
		max-width: 6.875rem;
	}
}
.slide-index__text {
	display: none;
	opacity: 0;
	padding-top: 4rem;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 20.25rem;
	    flex: 0 0 20.25rem;
	max-width: 20.25rem;
	position: relative;
	max-height: 28.125rem;
	overflow: hidden;
}
@media (max-width: 767px) {
	.slide-index__text {
		padding-top: 2.5rem;
		opacity: 1 !important;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.slide-index__text {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 68%;
		    flex: 0 0 68%;
		max-width: 68%;
	}
}
@media (max-width: 567px) {
	.slide-index__text {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
	}
}
.slide-index__text h3 {
	line-height: .85;
	font-size: 2.5em;
}
@media (max-width: 767px) {
	.slide-index__text h3 {
		font-size: 1.875em;
		line-height: 1.13;
	}
}
.slide-index__text__links {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@media (max-width: 767px) {
	.slide-index__text__links {
		padding-top: 1.0625rem;
		padding-bottom: 3.375rem;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
	}
}
.slide-index__text__links a {
	color: #d74d39;
	font-size: .875em;
	font-weight: 700;
}
@media (max-width: 767px) {
	.slide-index__text__links a {
		font-size: 1em;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 1.375rem;
	}
}
.slide-index__text__links a:hover {
	text-decoration: underline;
	color: #000;
}
@media (max-width: 767px) {
	.slide-index__text__links .social-links {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
	}
}
.slide-index__text__links .social-links.hide-first li:first-child {
	margin-left: 0;
}
.slide-index__text__links .social-links li {
	margin-left: 1.875rem;
	margin-right: 0;
}
@media (max-width: 767px) {
	.slide-index__text__links .social-links li {
		margin-left: 0;
		margin-right: 1.4375rem;
	}
}
.slide-index__text__links .social-links li a svg {
	height: 1.25rem;
}
@media (max-width: 767px) {
	.slide-index__text__links .social-links li a svg {
		height: 1.875rem;
	}
}
.slide-index__text__links .social-links li a svg path, .slide-index__text__links .social-links li a svg circle {
	fill: #d74d39;
}
.slide-index__text__links .social-links li a:hover svg path, .slide-index__text__links .social-links li a:hover svg circle {
	fill: #000;
}
.slide-index__flag {
	display: none;
	opacity: 0;
	position: absolute;
	top: 3rem;
	left: 3.0625rem;
	width: 4.375rem;
	height: 4.375rem;
}
@media (max-width: 767px) {
	.slide-index__flag {
		width: 3.625rem;
		height: 3.625rem;
		top: 1.625rem;
		left: 7.3125rem;
		opacity: 1 !important;
	}
}
.slide-index__flag img {
	width: 100%;
}
@media (max-width: 767px) {
	.slider-index > * {
		opacity: 1 !important;
		visibility: visible;
	}
}
@-webkit-keyframes visible-slide {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes visible-slide {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.slider-factory {
	padding-bottom: 5.125rem;
}
@media (max-width: 767px) {
	.slider-factory {
		margin-bottom: 1.9375rem;
	}
}
.slider-factory .slider-arrow {
	bottom: .625rem;
}
@media (max-width: 767px) {
	.slider-factory .slider-arrow {
		bottom: .8125rem;
	}
}
.slider-factory .slider-arrow.prev {
	right: 9.375rem;
}
@media (max-width: 767px) {
	.slider-factory .slider-arrow.prev {
		right: 5.3125rem;
	}
}
.slider-factory .slider-arrow.next {
	right: 3.125rem;
}
@media (max-width: 767px) {
	.slider-factory .slider-arrow.next {
		right: 0;
	}
}
@media (max-width: 767px) {
	.slider-factory .swiper-slide {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}
.slider-factory .swiper-slide img {
	max-width: 100%;
	width: calc(100% - 3.125rem);
}
@media (min-width: 992px) and (max-width: 1199px) {
	.slider-factory .swiper-slide img {
		max-width: 17.5rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-factory .swiper-slide img {
		max-width: 15.625rem;
	}
}
@media (max-width: 767px) {
	.slider-factory .swiper-slide img {
		width: 100%;
		height: 13.875rem;
		-o-object-fit: cover;
		   object-fit: cover;
	}
}
.slider-factory .swiper-pagination {
	font-size: 3.625em;
	left: -.3125rem;
	bottom: -.25rem;
	color: #fff;
}
@media (max-width: 767px) {
	.slider-factory .swiper-pagination {
		top: auto;
		bottom: .125rem;
		font-size: 3.5em;
		left: 0;
	}
}
.slider-factory .swiper-pagination span {
	font-size: 2.25rem;
}
@media (max-width: 767px) {
	.slider-factory .swiper-pagination span {
		font-size: 2rem;
	}
}
.slider-inner {
	padding-bottom: 3.0625rem;
	margin-bottom: 4.6875rem;
}
@media (max-width: 767px) {
	.slider-inner {
		padding-bottom: 5.0625rem;
		margin-bottom: 4.0625rem;
	}
}
.slider-inner .slider-arrow {
	bottom: .625rem;
	width: 1.625rem;
	height: 1.625rem;
}
@media (max-width: 767px) {
	.slider-inner .slider-arrow {
		width: 2.5rem;
		height: 2.5rem;
	}
}
.slider-inner .slider-arrow svg {
	height: .625rem;
	width: auto;
}
@media (max-width: 767px) {
	.slider-inner .slider-arrow svg {
		height: .9375rem;
	}
}
.slider-inner .slider-arrow.prev {
	left: 15.625rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.slider-inner .slider-arrow.prev {
		left: 11.25rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-inner .slider-arrow.prev {
		left: 11.25rem;
	}
}
@media (max-width: 767px) {
	.slider-inner .slider-arrow.prev {
		left: 0;
	}
}
.slider-inner .slider-arrow.next {
	right: 15.625rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.slider-inner .slider-arrow.next {
		right: 11.25rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-inner .slider-arrow.next {
		right: 11.25rem;
	}
}
@media (max-width: 767px) {
	.slider-inner .slider-arrow.next {
		right: 0;
	}
}
.slider-inner .swiper-slide img {
	margin-top: 0;
	margin-bottom: 0;
}
.slider-inner .swiper-pagination {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-top: .3125rem;
	padding-bottom: .3125rem;
	bottom: .6875rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.slider-inner .swiper-pagination {
		bottom: .875rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-inner .swiper-pagination {
		bottom: .875rem;
	}
}
@media (max-width: 767px) {
	.slider-inner .swiper-pagination {
		top: auto;
		bottom: 1.375rem;
	}
}
.slider-inner .swiper-pagination-bullet {
	height: .25rem;
	width: .25rem;
	opacity: .2;
	border: .08125rem solid #000;
	background-color: #fff;
	margin: 0 .46875rem !important;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.slider-inner .swiper-pagination-bullet:hover {
	background-color: #000;
	opacity: 1;
}
.slider-inner .swiper-pagination-bullet-active {
	background-color: #000;
	opacity: 1;
	-webkit-transform: scale(2);
	    -ms-transform: scale(2);
	        transform: scale(2);
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-index2 {
		background-color: #fff;
	}
}
@media (max-width: 767px) {
	.slider-index2 {
		background-color: #fff;
		padding-top: .625rem;
		overflow: visible;
	}
}
.slider-index2:after {
	width: 59.375rem;
	height: 50rem;
}
@media (min-width: 1400px) {
	.slider-index2:after {
		left: 6.1875rem;
	}
}
@media (min-width: 1550px) {
	.slider-index2:after {
		height: 41.8125rem;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.slider-index2:after {
		left: 0;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-index2:after {
		left: 0;
	}
}
@media (max-width: 767px) {
	.slider-index2:after {
		display: none;
	}
}
@media (max-width: 767px) {
	.slider-index2 .swiper-wrapper {
		padding-top: 0;
	}
}
@media (min-width: 1200px) {
	.slider-index2 .swiper-wrapper.set-transform {
		-webkit-transform: translate3d(-.75rem, 0, 0) !important;
		        transform: translate3d(-.75rem, 0, 0) !important;
	}
}
.slider-index2 .slide-index__text__links {
	position: static;
	max-width: 20.0625rem;
	width: 100%;
}
@media (max-width: 767px) {
	.slider-index2 .slide-index__text__links a {
		font-size: 1.125em;
		margin-bottom: 2.5625rem;
	}
}
.slider-index2 .slide-index__text__links .social-links {
	padding-top: .625rem;
}
@media (max-width: 767px) {
	.slider-index2 .slide-index__text__links .social-links li {
		margin-right: 3.0625rem;
	}
}
.slider-index2 .swiper-slide {
	height: 50rem !important;
}
@media (min-width: 1550px) {
	.slider-index2 .swiper-slide {
		height: 41.875rem !important;
	}
}
@media (max-width: 767px) {
	.slider-index2 .swiper-slide {
		height: auto !important;
	}
}
.slider-index2 .swiper-slide.swiper-slide-active {
	width: 60.625rem !important;
}
@media (max-width: 767px) {
	.slider-index2 .swiper-slide.swiper-slide-active {
		width: 100% !important;
	}
}
.slider-index2 .swiper-slide.swiper-slide-active .slide-index-2-tabs {
	opacity: 1;
	visibility: visible;
	z-index: 10;
}
.slider-index2 .swiper-slide.swiper-slide-active .slide-index__img {
	-webkit-transform: scale(1.52);
	    -ms-transform: scale(1.52);
	        transform: scale(1.52);
}
@media (min-width: 1600px) {
	.slider-index2 .swiper-slide.swiper-slide-active .slide-index__img {
		-webkit-transform: scale(1.7);
		    -ms-transform: scale(1.7);
		        transform: scale(1.7);
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-index2 .swiper-slide.swiper-slide-active .slide-index__img {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 17.5rem;
		    flex: 0 0 17.5rem;
		max-width: 17.5rem;
	}
}
@media (max-width: 767px) {
	.slider-index2 .swiper-slide.swiper-slide-active .slide-index__img {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
		-webkit-transform: none;
		    -ms-transform: none;
		        transform: none;
	}
}
.slider-index2 .slider-arrow {
	bottom: 19.125rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.slider-index2 .slider-arrow {
		bottom: auto;
		top: 35%;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-index2 .slider-arrow {
		bottom: auto;
		top: 40%;
	}
}
@media (max-width: 767px) {
	.slider-index2 .slider-arrow {
		bottom: auto;
		top: 20.8125rem;
	}
}
.slider-index2 .slider-arrow.prev {
	left: 2.8125rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.slider-index2 .slider-arrow.prev {
		left: 1.25rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-index2 .slider-arrow.prev {
		left: .625rem;
	}
}
@media (max-width: 767px) {
	.slider-index2 .slider-arrow.prev {
		left: 1.6875rem;
	}
}
.slider-index2 .slider-arrow.next {
	left: auto;
	right: 2.8125rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.slider-index2 .slider-arrow.next {
		right: 1.25rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.slider-index2 .slider-arrow.next {
		right: .625rem;
	}
}
@media (max-width: 767px) {
	.slider-index2 .slider-arrow.next {
		right: 1.6875rem;
	}
}
.slide-index2 {
	display: none;
}
.slide-index2.active {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.slide-index2 .slide-index__img {
	padding-bottom: 17.1875rem;
}
@media (max-width: 767px) {
	.slide-index2 .slide-index__img {
		padding-bottom: 0;
	}
}
.slide-index2 .slide-index__text {
	padding-top: 4.8125rem;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 31.25rem;
	    flex: 0 0 31.25rem;
	max-width: 31.25rem;
	max-height: unset;
}
@media (min-width: 768px) and (max-width: 991px) {
	.slide-index2 .slide-index__text {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20.3125rem;
		    flex: 0 0 20.3125rem;
		max-width: 20.3125rem;
	}
}
@media (max-width: 767px) {
	.slide-index2 .slide-index__text {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
		padding-top: 6.875rem;
	}
}
.slide-index2 h3 {
	line-height: .85;
	font-size: 2.5em;
	margin-bottom: .25rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.slide-index2 h3 {
		font-size: 2.125em;
	}
}
@media (max-width: 767px) {
	.slide-index2 h3 {
		font-size: 1.875em;
		line-height: 1.13;
		margin-bottom: .0625rem;
	}
}
.sl-brand_heading {
	line-height: 1.63;
	font-size: 1em;
	margin-bottom: 2.1875rem;
}
@media (max-width: 767px) {
	.sl-brand_heading {
		margin-bottom: 3.125rem;
		max-height: 3.25rem;
		overflow: hidden;
	}
}
.sl-brand__tara {
	padding-top: 1.8125rem;
	max-width: 21.875rem;
	padding-bottom: 2.8125rem;
}
@media (max-width: 767px) {
	.sl-brand__tara {
		padding-top: 1.625rem;
		padding-bottom: 2.5rem;
	}
}
.sl-brand__tara__list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
.sl-brand__tara__list li {
	margin-right: 1.25rem;
	margin-bottom: .9375rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	line-height: 1.22;
	font-size: 1.125em;
	font-weight: 700;
}
@media (max-width: 767px) {
	.sl-brand__tara__list li {
		margin-right: 0;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		    flex: 0 0 50%;
		max-width: 50%;
		margin-bottom: 1.5rem;
	}
}
.sl-brand__tara__list li img {
	margin-right: .9375rem;
}
.sl-brand__tara_heading {
	line-height: 1.86;
	font-size: .875em;
	font-weight: 700;
	border-bottom: .0625rem solid #000;
	padding-bottom: .625rem;
	margin-bottom: 1.5rem;
}
@media (max-width: 767px) {
	.sl-brand__tara_heading {
		font-size: 1em;
		padding-bottom: .5625rem;
		margin-bottom: 1.875rem;
	}
}
@media (max-width: 767px) {
	.container-swiper2 {
		margin-bottom: 4.75rem;
	}
}
.container-swiper2:before, .container-swiper2:after {
	width: 3.125rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.container-swiper2:before, .container-swiper2:after {
		display: none;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.container-swiper2:before, .container-swiper2:after {
		display: none;
	}
}
@media (max-width: 767px) {
	.container-swiper2:before, .container-swiper2:after {
		display: none;
	}
}
.slide-index-2-tabs {
	position: absolute;
	top: 28.25rem;
	left: 7.8125rem;
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	max-width: 9.5rem;
	width: 100%;
}
@media (max-width: 1549px) {
	.slide-index-2-tabs {
		top: 34.375rem;
	}
}
@media (max-width: 1200px) {
	.slide-index-2-tabs {
		top: 34.5rem;
	}
}
@media (max-width: 767px) {
	.slide-index-2-tabs {
		top: 15.125rem;
		left: auto;
		right: 1.25rem;
		max-width: 8.9375rem;
		opacity: 1;
		visibility: visible;
		z-index: 10;
	}
}
.slide-index-2-tabs .slide-index-2-tabs__list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	padding-left: 1.375rem;
	padding-right: 1.375rem;
}
@media (max-width: 767px) {
	.slide-index-2-tabs .slide-index-2-tabs__list {
		display: none;
	}
}
.slide-index-2-tabs .slide-index-2-tabs__list li {
	width: .25rem;
	height: .25rem;
	border-radius: 50%;
	background-color: #fff;
	opacity: .2;
	border: solid .0625rem #000;
	cursor: pointer;
	margin: 0 .5rem .5rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.slide-index-2-tabs .slide-index-2-tabs__list li:hover {
	opacity: 1;
}
.slide-index-2-tabs .slide-index-2-tabs__list li.active {
	background-color: #000;
	opacity: 1;
	-webkit-transform: scale(1.5);
	    -ms-transform: scale(1.5);
	        transform: scale(1.5);
}
.slide-index-2-tabs__arrow {
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 1.625rem;
	height: 1.625rem;
	border: .0625rem solid #000;
	border-radius: 50%;
	top: -.75rem;
	z-index: 200;
	cursor: pointer;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.slide-index-2-tabs__arrow {
		width: 2.5rem;
		height: 2.5rem;
		top: -1.1875rem;
	}
}
.slide-index-2-tabs__arrow svg g {
	background-color: #000;
}
.slide-index-2-tabs__arrow.left {
	left: 0;
}
@media (max-width: 767px) {
	.slide-index-2-tabs__arrow.left {
		left: 1.25rem;
	}
}
.slide-index-2-tabs__arrow.right {
	right: 0;
}
.slide-index-2-tabs__arrow:hover {
	background-color: #000;
}
.slide-index-2-tabs__arrow:hover svg g {
	fill: #fff;
}
table, tr, th, td {
	border: .0625rem solid #000;
}
table {
	width: 100%;
	margin-top: 4.375rem;
	margin-bottom: 7.5rem;
}
@media (max-width: 767px) {
	table {
		margin-top: 3.75rem;
		min-width: 200vw;
	}
}
table caption {
	caption-side: top;
	text-align: right;
}
table th {
	text-align: center;
	font-size: 1.125em;
	padding: .5625rem .875rem .4375rem;
}
table th, table td {
	line-height: 1.89;
	vertical-align: baseline;
}
table td {
	font-size: .875em;
	padding: 1.125rem 1rem 1.0625rem;
}
@media (max-width: 767px) {
	.table-wrapper {
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		margin-top: 3.75rem;
		margin-bottom: 7.5rem;
	}
	.table-wrapper table {
		margin-top: 0;
		margin-bottom: 0;
	}
}
@media (max-width: 567px) {
	.table-wrapper {
		margin-left: -8.333333333333334%;
		margin-right: -8.333333333333334%;
		padding-left: 8.333333333333334%;
	}
}
audio {
	width: 100%;
}
video {
	width: 100%;
}
.video-element {
	width: 100%;
	max-width: 100%;
}
.video-wrapper {
	margin-bottom: 9.125rem;
	position: relative;
}
@media (min-width: 768px) and (max-width: 991px) {
	.video-wrapper {
		margin-bottom: 13.125rem;
	}
}
@media (max-width: 767px) {
	.video-wrapper {
		margin-bottom: 10.25rem;
	}
}
.video-wrapper__sound {
	background-color: rgba(0,0,0,.2);
	height: 1.8125rem;
	width: 1.75rem;
	position: absolute;
	bottom: -.125rem;
	right: -13.3125rem;
	z-index: 10;
	overflow: hidden;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.video-wrapper__sound {
		right: -10rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.video-wrapper__sound {
		right: 2.1875rem;
		bottom: -7.5rem;
	}
}
@media (max-width: 767px) {
	.video-wrapper__sound {
		display: none;
	}
}
.video-wrapper__sound_overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000;
	width: 0;
	z-index: -1;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (min-width: 1200px) {
	.video-wrapper__sound_overlay {
		top: .0625rem;
		left: .0625rem;
		right: .0625rem;
		bottom: .0625rem;
	}
}
.video-wrapper__sound svg {
	width: 1.75rem;
	height: auto;
}
.video-wrapper .mejs__overlay-button {
	background: url('../img/mediaelement/mejs-controls.svg') no-repeat;
	display: none;
}
.video-wrapper .mejs__overlay-loading-bg-img {
	background: transparent url('../img/mediaelement/mejs-controls.svg') -160px -40px no-repeat;
}
.video-wrapper .mejs__time-loaded, .video-wrapper .mejs__time-total {
	background-color: rgba(0,0,0,.2);
	border-radius: 0;
}
.video-wrapper .mejs__time-loaded:focus, .video-wrapper .mejs__time-total:focus {
	outline: none;
}
.video-wrapper .mejs__time-current, .video-wrapper .mejs__time-handle {
	background-color: #000;
}
.video-wrapper .mejs__time-handle {
	display: none;
}
.video-wrapper .mejs__volume-button {
	display: none;
}
.video-wrapper .mejs__horizontal-volume-slider {
	position: absolute;
	right: -15.625rem;
	bottom: 1.25rem;
	width: 6.25rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.video-wrapper .mejs__horizontal-volume-slider {
		right: -12.5rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.video-wrapper .mejs__horizontal-volume-slider {
		right: 0;
		bottom: -5.9375rem;
	}
}
@media (max-width: 767px) {
	.video-wrapper .mejs__horizontal-volume-slider {
		right: 0;
		bottom: -5.9375rem;
	}
}
.video-wrapper .mejs__horizontal-volume-total {
	width: 6.25rem;
	background-color: rgba(0,0,0,.2);
	border-radius: 0;
	height: .0625rem;
}
.video-wrapper .mejs__horizontal-volume-current {
	background-color: #000;
	border-radius: 0;
}
.video-wrapper .mejs__horizontal-volume-current:after {
	content: '';
	display: block;
	position: absolute;
	width: .8125rem;
	height: .8125rem;
	background-color: #000;
	right: 0;
	top: -.375rem;
	border-radius: 50%;
}
.video-wrapper .mejs__fullscreen-button, .video-wrapper .mejs__time {
	display: none;
}
.video-wrapper .mejs__controls {
	opacity: 1 !important;
	display: block !important;
	visibility: visible !important;
	-webkit-clip-path: none !important;
	        clip-path: none !important;
	clip: auto !important;
	overflow: visible !important;
	bottom: -3.8125rem;
	padding: 0;
	background: none !important;
}
@media (max-width: 767px) {
	.video-wrapper .mejs__controls {
		bottom: -3.0625rem;
	}
}
.video-wrapper .mejs__time-handle, .video-wrapper .mejs__time-handle-content, .video-wrapper .mejs__time-hovered {
	display: none !important;
}
.video-wrapper .mejs__time-float {
	background-color: transparent;
	border: none;
}
.video-wrapper .mejs__time-float-current {
	padding-top: .8125rem;
	opacity: .2;
	-webkit-text-stroke: .08125rem #000;
	        text-stroke: .08125rem #000;
	font-size: 2.5rem;
	font-weight: bold;
	line-height: .85;
	color: rgba(0,0,0,0);
}
@media (max-width: 767px) {
	.video-wrapper .mejs__time-float-current {
		font-size: .875rem;
		color: #ccc;
		        text-stroke: none;
		-webkit-text-stroke: none;
		padding-top: .4375rem;
	}
}
.video-wrapper .mejs__time-total, .video-wrapper .mejs__time-buffering, .video-wrapper .mejs__time-loaded, .video-wrapper .mejs__time-current, .video-wrapper .mejs__time-float, .video-wrapper .mejs__time-hovered, .video-wrapper .mejs__time-float-current, .video-wrapper .mejs__time-float-corner, .video-wrapper .mejs__time-marker {
	height: .0625rem;
	margin: 0;
}
.video-wrapper .mejs__time-rail {
	width: 100%;
	margin: 0;
	padding: 0;
	height: .0625rem;
}
.video-wrapper .mejs__playpause-button {
	position: absolute;
	left: -15.625rem;
	bottom: 2.4375rem;
	height: 4.6875rem;
	width: 4.6875rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	line-height: normal;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.video-wrapper .mejs__playpause-button {
		left: -12.5rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.video-wrapper .mejs__playpause-button {
		left: 0;
		bottom: -5rem;
	}
}
@media (max-width: 767px) {
	.video-wrapper .mejs__playpause-button {
		left: 0;
		bottom: -2.1875rem;
		height: 3.125rem;
		width: 3.125rem;
	}
}
.video-wrapper .mejs__playpause-button:hover button:after {
	opacity: 1;
}
.video-wrapper .mejs__playpause-button button {
	width: 100%;
	height: 100%;
	margin: 0;
	position: relative;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.video-wrapper .mejs__playpause-button button:focus {
	outline: none;
}
.video-wrapper .mejs__playpause-button.mejs__play button, .video-wrapper .mejs__playpause-button.mejs__replay button {
	background: url('../img/video/play.svg') no-repeat center center;
	background-size: cover;
}
.video-wrapper .mejs__playpause-button.mejs__play:hover button, .video-wrapper .mejs__playpause-button.mejs__replay:hover button {
	background: url('../img/video/play2.svg') no-repeat center center;
	background-size: cover;
}
.video-wrapper .mejs__playpause-button.mejs__pause button {
	background: url('../img/video/pause.svg') no-repeat center center;
	background-size: cover;
}
.video-wrapper .mejs__playpause-button.mejs__pause:hover button {
	background: url('../img/video/pause2.svg') no-repeat center center;
	background-size: cover;
}
.video-wrapper .mejs__replay button {
	background: url('../img/video/play.svg') no-repeat center center;
	background-size: cover;
}
.video-wrapper .mejs__replay:hover button {
	background: url('../img/video/play2.svg') no-repeat center center;
	background-size: cover;
}
.video-wrapper .mejs__container.mejs__video, .video-wrapper .mejs-container.mejs-video {
	overflow: visible;
}
.accordion_heading {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	color: #000;
	cursor: pointer;
	padding: 0 0 .1875rem;
	height: 4.4375rem;
	font-size: 1.375em;
	margin: 0;
	position: relative;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	border-bottom: .0625rem solid #000;
	font-weight: 700;
}
@media (max-width: 767px) {
	.accordion_heading {
		font-size: 1.25em;
		height: 4.125rem;
	}
}
.accordion_heading:focus {
	outline: none;
}
.accordion_heading:after {
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-left: .375rem solid transparent;
	border-right: .375rem solid transparent;
	border-top: .375rem solid #000;
	top: calc(50% - .188rem);
	right: .9375rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.accordion_heading.active:after {
	-webkit-transform: rotate(180deg);
	    -ms-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.accordion__panel {
	display: none;
	padding: .75rem 0 2.875rem;
}
@media (max-width: 767px) {
	.accordion__panel {
		padding-bottom: 5.6875rem;
	}
}
input, textarea, select {
	border: none;
	border-bottom: .0625rem solid #ccc;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	width: 100%;
	height: 3.125rem;
	border-radius: 0;
	padding: 0 1.875rem .1875rem 0;
	font-size: 1em;
	margin-bottom: .5rem;
	-webkit-appearance: none;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
input:focus, textarea:focus, select:focus {
	outline: none;
	border-bottom-color: #000;
}
input.error, textarea.error, select.error {
	color: #ff1530;
	border-color: #ff1530;
}
input.error:-moz-placeholder, textarea.error:-moz-placeholder, select.error:-moz-placeholder {
	color: #ff1530;
}
input.error::-moz-placeholder, textarea.error::-moz-placeholder, select.error::-moz-placeholder {
	color: #ff1530;
}
input.error:-ms-input-placeholder, textarea.error:-ms-input-placeholder, select.error:-ms-input-placeholder {
	color: #ff1530;
}
input.error::-webkit-input-placeholder, textarea.error::-webkit-input-placeholder, select.error::-webkit-input-placeholder {
	color: #ff1530;
}
input:-moz-placeholder, textarea:-moz-placeholder {
	color: #000;
	opacity: .3;
}
input::-moz-placeholder, textarea::-moz-placeholder {
	color: #000;
	opacity: .3;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
	color: #000;
	opacity: .3;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
	color: #000;
	opacity: .3;
}
input:-webkit-autofill {
	-webkit-transition: background-color 5000s ease-in-out 0s;
	     -o-transition: background-color 5000s ease-in-out 0s;
	        transition: background-color 5000s ease-in-out 0s;
	-webkit-text-fill-color: #999 !important;
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type=number] {
	-moz-appearance: textfield;
}
label {
	font-weight: 400;
	font-size: 1em;
	margin-bottom: .9375rem;
}
textarea {
	resize: none;
	height: 8rem;
	margin-bottom: .9375rem;
}
@media (max-width: 767px) {
	textarea {
		height: 9.375rem;
	}
}
.flatpickr-day {
	border-radius: 0;
}
.flatpickr-day.selected {
	background-color: #ba0c2f;
	border: none;
}
.input-group {
	position: relative;
	margin-bottom: .5rem;
}
@media (max-width: 767px) {
	.input-group {
		margin-bottom: .375rem;
	}
}
.input-group__icon {
	position: absolute;
	top: .3125rem;
	right: 0;
	bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: transparent;
	padding: 0;
	border-radius: 50%;
	height: 1.9375rem;
	width: 1.9375rem;
}
.input-group__icon svg {
	width: .9375rem;
	height: auto;
}
.input-group__icon svg rect {
	stroke: #000;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.input-group__icon:hover {
	background-color: #000;
}
.input-group__icon:hover svg rect {
	stroke: #fff;
}
.input-group input, .input-group textarea, .input-group select {
	margin-bottom: 0;
}
.input-group textarea {
	padding-top: .875rem;
}
.input-group.select-group .chosen-container {
	margin-bottom: 0;
}
@media (max-width: 767px) {
	.input-group.select-group:after {
		content: '';
		display: block;
		position: absolute;
		width: 0;
		height: 0;
		border-left: .25rem solid transparent;
		border-right: .25rem solid transparent;
		border-top: .25rem solid #000;
		top: calc(50% - .125rem);
		right: .9375rem;
	}
}
.input-group.error input {
	color: #ff1530;
	border-color: #ff1530;
}
.input-group.error input:-moz-placeholder {
	color: #ff1530;
}
.input-group.error input::-moz-placeholder {
	color: #ff1530;
}
.input-group.error input:-ms-input-placeholder {
	color: #ff1530;
}
.input-group.error input::-webkit-input-placeholder {
	color: #ff1530;
}
.input-group.error .chosen-single.chosen-default {
	border-bottom: .0625rem solid #ff1530;
}
.input-group.error .chosen-single.chosen-default span {
	color: #ff1530;
}
.input-group-datepicker__icon {
	position: absolute;
	top: .625rem;
	right: 0;
}
.input-group-datepicker__icon svg g {
	fill: #ccc;
}
form {
	position: relative;
}
form.error .form_error {
	opacity: 1;
	visibility: visible;
	z-index: 10;
}
form.success .form-inner {
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}
form.success .form-success {
	opacity: 1;
	visibility: visible;
	z-index: 10;
}
.form_error {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
	bottom: -1.75rem;
	color: #ba0c2f;
	font-size: .75em;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}
.form-success {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}
@media (max-width: 767px) {
	.form-search {
		margin-bottom: 1.875rem;
	}
}
@media (min-width: 768px) and (max-width: 812px) and (orientation: landscape) {
	.form-search {
		margin-bottom: 1.875rem;
	}
}
.form-search-big {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 2.5rem;
}
@media (max-width: 767px) {
	.form-search-big {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
	}
}
.form-search-big__left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
.form-search-big input, .form-search-big select {
	font-size: .875em;
}
.form-search-big .input-group {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 17.5rem;
	    flex: 0 0 17.5rem;
	max-width: 17.5rem;
	margin-right: 1.1875rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.form-search-big .input-group {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 15rem;
		    flex: 0 0 15rem;
		max-width: 15rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.form-search-big .input-group {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 17.5rem;
		    flex: 0 0 17.5rem;
		max-width: 17.5rem;
	}
}
@media (max-width: 767px) {
	.form-search-big .input-group {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
		margin-right: 0;
	}
}
.form-search-big .input-group.select-group-lang {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 5rem;
	    flex: 0 0 5rem;
	max-width: 5rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.form-search-big .input-group.select-group-lang {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 15rem;
		    flex: 0 0 15rem;
		max-width: 15rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.form-search-big .input-group.select-group-lang {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 17.5rem;
		    flex: 0 0 17.5rem;
		max-width: 17.5rem;
	}
}
@media (max-width: 767px) {
	.form-search-big .input-group.select-group-lang {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
	}
}
.form-search-big.form-brands {
	margin-bottom: 4.5625rem;
}
@media (max-width: 767px) {
	.form-search-big.form-brands {
		margin-bottom: 3.9375rem;
	}
}
.form-search-big .btn {
	height: 3.125rem;
}
@media (max-width: 767px) {
	.form-search-big .btn {
		margin-top: 2.5rem;
		height: 3.5rem;
	}
}
.form-search-big .btn-reset {
	height: auto;
	margin: 1.0625rem auto;
}
.filter-container {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	max-width: 100%;
	display: none;
}
@media (max-width: 767px) {
	.form-search-publications {
		margin-bottom: 3.4375rem;
	}
}
.form-anketa {
	background-color: #fff;
	padding: 3.6875rem 6.25rem 3.75rem;
	margin-top: 5.1875rem;
	margin-bottom: 4.6875rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.form-anketa {
		padding-left: 5rem;
		padding-right: 5rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.form-anketa {
		padding-left: 3.75rem;
		padding-right: 3.75rem;
	}
}
@media (max-width: 767px) {
	.form-anketa {
		margin-top: 3.375rem;
		padding-left: 8.333333333333334%;
		padding-right: 8.333333333333334%;
		margin-bottom: 5rem;
	}
}
.form-anketa.error .form-anketa_error {
	opacity: 1;
	visibility: visible;
	z-index: 10;
}
.form-anketa_error {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	    flex: 0 0 50%;
	max-width: 50%;
	color: #ff1530;
	font-size: .875em;
	padding-top: .5rem;
	padding-left: 1.25rem;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.form-anketa_error {
		padding-left: 0;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.form-anketa_error {
		padding-left: 0;
	}
}
@media (max-width: 767px) {
	.form-anketa_error {
		padding-left: 0;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
	}
}
.form-anketa .input-group {
	-webkit-box-flex: calc(50% - 1.5625rem);
	-ms-flex: calc(50% - 1.5625rem);
	    flex: calc(50% - 1.5625rem);
	max-width: calc(50% - 1.5625rem);
	margin-bottom: 1.75rem;
}
@media (max-width: 767px) {
	.form-anketa .input-group {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 1.3125rem;
	}
}
.form-anketa .textarea-group {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	max-width: 100%;
	margin-bottom: 2.5rem;
}
.form-anketa .form-inner {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.form-anketa .form-success {
	padding: 3.6875rem 6.25rem 3.75rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.form-anketa .form-success {
		padding-left: 5rem;
		padding-right: 5rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.form-anketa .form-success {
		padding-left: 3.75rem;
		padding-right: 3.75rem;
	}
}
@media (max-width: 767px) {
	.form-anketa .form-success {
		padding-left: 8.333333333333334%;
		padding-right: 8.333333333333334%;
	}
}
.form-anketa .form-success p {
	max-width: 22.5rem;
	margin-bottom: 4.875rem;
}
.form-anketa .form-success img {
	margin-bottom: 2.6875rem;
	width: 7.1875rem;
}
@media (min-width: 1200px) {
	.input-career {
		margin-left: 18.6875rem;
	}
}
.radiocheck {
	position: relative;
	padding: 0;
	margin-bottom: .9375rem;
}
.radiocheck input {
	position: absolute;
	opacity: 1;
}
.radiocheck input + .radiocheck__icon {
	position: relative;
	cursor: pointer;
	font-weight: 400;
	z-index: 4000;
}
.radiocheck input + .radiocheck__icon:before, .radiocheck input + .radiocheck__icon:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: .875rem;
	height: .875rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.radiocheck input + .radiocheck__icon:before {
	border: .0625rem solid #e8e8e8;
	opacity: 1;
}
.radiocheck input + .radiocheck__icon:after {
	border: .0625rem solid #000;
	opacity: 0;
	background: url('../img/icons/check.svg') no-repeat center center;
	background-size: cover;
}
.radiocheck__text {
	padding-left: 1.625rem;
	font-size: .75em;
	cursor: pointer;
	text-decoration: underline;
	position: relative;
	top: .1875rem;
}
.radiocheck__text:hover {
	text-decoration: none;
	color: #000;
}
.radiocheck.error + .radiocheck__text {
	color: #ba0c2f;
}
.radiocheck:hover .radiocheck__text {
	text-decoration: underline;
}
input[type='radio'] + .radiocheck__icon:after, input[type='radio'] + .radiocheck__icon:before {
	border-radius: 50%;
}
input:checked + .radiocheck__icon:before {
	opacity: 0;
}
input:checked + .radiocheck__icon:after {
	opacity: 1;
}
.radiocheck-wrapper {
	margin-bottom: .625rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.radiocheck-wrapper2 {
	margin-top: 5rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.radiocheck-wrapper2 {
		margin-top: 3.75rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.radiocheck-wrapper2 {
		margin-top: 2.8125rem;
	}
}
@media (max-width: 767px) {
	.radiocheck-wrapper2 {
		margin-top: 1.875rem;
	}
}
.chosen-rtl .chosen-search input[type='text'], .chosen-container-single .chosen-single abbr, .chosen-container-single .chosen-single div b, .chosen-container-single .chosen-search input[type='text'], .chosen-container-multi .chosen-choices .search-choice .search-choice-close, .chosen-container .chosen-results-scroll-down span, .chosen-container .chosen-results-scroll-up span {
	background-image: url('../img/chosen/chosen-sprite.png') !important;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
	.chosen-rtl .chosen-search input[type='text'], .chosen-container-single .chosen-single abbr, .chosen-container-single .chosen-single div b, .chosen-container-single .chosen-search input[type='text'], .chosen-container-multi .chosen-choices .search-choice .search-choice-close, .chosen-container .chosen-results-scroll-down span, .chosen-container .chosen-results-scroll-up span {
		background-image: url('../img/chosen/chosen-sprite@2x.png') !important;
	}
}
.chosen-container {
	width: 100%;
	font-size: 1em;
	margin-bottom: .9375rem;
}
.chosen-container:after {
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-left: .25rem solid transparent;
	border-right: .25rem solid transparent;
	border-top: .25rem solid #000;
	top: 1.125rem;
	right: .75rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.chosen-container:focus {
	outline: none;
}
.chosen-container .chosen-single {
	padding: 0;
	height: auto;
	border-radius: 0;
	border: none;
	border-bottom: .0625rem solid #ccc;
	background: #fff;
	-webkit-box-shadow: none;
	        box-shadow: none;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	font-size: .875em;
}
.chosen-container .chosen-single span {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 3.0625rem;
	padding: 0 0 .1875rem;
	font-size: 1em;
}
.chosen-container .chosen-default span {
	opacity: .5;
}
.chosen-container-single .chosen-search {
	padding: 0 .3125rem;
	margin-bottom: .9375rem;
}
.chosen-container-single .chosen-search input[type='text'] {
	font-size: 1em;
	border: .0625rem solid #a9a9a9;
	padding: .6875rem .625rem .75rem;
	background-image: none !important;
}
.chosen-container.chosen-with-drop:after {
	-webkit-transform: rotate(180deg);
	    -ms-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.chosen-container.chosen-container-active {
	-webkit-box-shadow: none;
	        box-shadow: none;
}
.chosen-container.chosen-container-active .chosen-single {
	-webkit-box-shadow: none;
	        box-shadow: none;
	border: none;
	border-bottom: .0625rem solid #ccc;
	background: none;
}
.chosen-container-single .chosen-drop {
	border-radius: 0;
	border-width: none;
	border: none;
	padding: 0;
	-webkit-box-shadow: none;
	        box-shadow: none;
	top: calc(100% + .063rem);
	right: -1.1875rem;
	left: -1.1875rem;
	width: calc(100% + 2.375rem);
}
.chosen-container-single .chosen-drop .chosen-results {
	padding: 0;
	margin: 0;
	-webkit-box-shadow: none;
	        box-shadow: none;
}
.chosen-container-single .chosen-drop .chosen-results .active-result {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 3.0625rem;
	padding: 0 1.1875rem .0625rem;
	font-size: .875em;
	line-height: normal;
	color: #000;
	border: .0625rem solid #fff;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	position: relative;
}
.chosen-container-single .chosen-drop .chosen-results .active-result:after {
	content: '';
	display: block;
	position: absolute;
	left: 1.1875rem;
	right: 1.1875rem;
	bottom: -.0625rem;
	height: .0625rem;
	background-color: #ccc;
}
.chosen-container-single .chosen-drop .chosen-results .active-result.highlighted {
	background: #fff;
	border-color: #000;
}
.chosen-container-single .chosen-drop .chosen-results .active-result.highlighted:after {
	background-color: #000;
}
.chosen-container-single .chosen-drop .chosen-results .result-selected, .chosen-container-single .chosen-drop .chosen-results .disabled-result {
	background: #fff;
	display: none;
}
.chosen-container-single .chosen-single div b {
	display: none;
}
.chosen-container .chosen-results li.no-results {
	background: #fff;
	color: #a9a9a9;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 3.375rem;
	padding: 0 .9375rem .1875rem;
}
.news-block {
	margin-top: .5rem;
	display: block;
	margin-bottom: 4.25rem;
}
@media (max-width: 767px) {
	.news-block {
		margin-top: 0;
		margin-bottom: 5.375rem;
	}
}
.news-block__img {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	overflow: hidden;
	height: 11.25rem;
	margin-bottom: .5625rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.news-block__img {
		height: 8.5625rem;
	}
}
.news-block__img img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	   object-fit: cover;
}
.news-block__date {
	opacity: .3;
	font-size: .875em;
	line-height: 2.43;
	margin-bottom: .75rem;
}
@media (max-width: 767px) {
	.news-block__date {
		margin-bottom: .8125rem;
	}
}
.news-block__text {
	border-bottom: .0625rem solid #000;
	margin-bottom: 2.125rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.news-block__text h4 {
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.news-block__intership {
	opacity: .2;
	-webkit-text-stroke: .1rem #000;
	        text-stroke: .1rem #000;
	font-size: 3.75em;
	font-weight: bold;
	line-height: .57;
	color: rgba(0,0,0,0);
	margin-bottom: 3.625rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.news-block__intership {
		line-height: .89;
		font-size: 2.375em;
		margin-bottom: 1.0625rem;
	}
}
.news-block .link-more {
	margin-top: 0;
	padding-right: 2.3125rem;
}
@media (max-width: 767px) {
	.news-block .link-more {
		padding-right: 3rem;
	}
}
.news-block .link-more__angle {
	width: 1.625rem;
	height: 1.625rem;
}
@media (max-width: 767px) {
	.news-block .link-more__angle:before {
		background-color: #000 !important;
	}
	.news-block .link-more__angle svg g {
		fill: #fff;
	}
}
.news-block .link-more_text {
	font-size: .9375em;
}
@media (max-width: 767px) {
	.news-block .link-more_text {
		font-size: .9375em !important;
	}
}
.news-block:hover {
	color: #000;
}
.news-block:hover .news-block__img {
	opacity: .7;
}
.news-block:hover .news-block__text {
	opacity: .6;
}
.news-block:hover .news-block__text h4 {
	text-decoration: underline;
}
.news-block.project-block {
	margin-top: 1.1875rem;
	margin-bottom: 5.3125rem;
}
@media (max-width: 767px) {
	.news-block.project-block {
		margin-top: 0;
		margin-bottom: 4.625rem;
	}
}
.news-block.project-block .news-block__img {
	height: 15.625rem;
}
@media (max-width: 767px) {
	.news-block.project-block .news-block__img {
		height: 7.1875rem;
	}
}
.news-block.project-block .news-block__date {
	font-size: .875em;
	line-height: 1.2;
	margin-bottom: .5rem;
}
@media (max-width: 767px) {
	.news-block.project-block .news-block__date {
		margin-bottom: .5625rem;
	}
}
.news-block.project-block .news-block__text h4 {
	font-size: 1.375em;
	margin-bottom: 1.125rem;
}
@media (max-width: 767px) {
	.news-block.project-block .news-block__text h4 {
		font-size: 1.25em;
		margin-bottom: 1.0625rem;
	}
}
.news-block.internship-block {
	border: solid .0625rem #f4f4f5;
	background-color: #f4f4f5;
	padding: 3.875rem 3.125rem 1.75rem;
	margin-top: 0;
	position: relative;
}
@media (max-width: 767px) {
	.news-block.internship-block {
		padding: 2.125rem 1.625rem 1.1875rem;
		margin-bottom: 1.875rem;
	}
}
.news-block.internship-block .news-block__img {
	position: absolute;
	right: 6rem;
	bottom: 4.625rem;
	display: block;
	margin-bottom: 0;
	height: auto;
	opacity: 0;
	width: 10.125rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.news-block.internship-block .news-block__img {
		right: 2.5rem;
	}
}
@media (max-width: 767px) {
	.news-block.internship-block .news-block__img {
		opacity: 1;
		height: 4.9375rem;
		right: auto;
		left: 2.1875rem;
		bottom: 3.6875rem;
	}
}
.news-block.internship-block .news-block__img img {
	height: auto;
	-o-object-fit: unset;
	   object-fit: unset;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	pointer-events: none;
	-webkit-transform: translateY(4.625rem);
	    -ms-transform: translateY(4.625rem);
	        transform: translateY(4.625rem);
}
@media (max-width: 767px) {
	.news-block.internship-block .news-block__img img {
		height: 100%;
		width: auto;
		-webkit-transform: none;
		    -ms-transform: none;
		        transform: none;
	}
}
.news-block.internship-block .news-block__text {
	margin-bottom: 1.625rem;
}
@media (max-width: 767px) {
	.news-block.internship-block .news-block__text {
		margin-bottom: 1.125rem;
	}
}
.news-block.internship-block .news-block__text h4 {
	font-size: 1.375em;
	line-height: 1.45;
	margin-bottom: 3rem;
	max-width: 14.375rem;
}
@media (max-width: 767px) {
	.news-block.internship-block .news-block__text h4 {
		font-size: 1em;
		line-height: 1.63;
		max-width: 11.25rem;
		margin-bottom: 6.0625rem;
	}
}
@media (max-width: 767px) {
	.news-block.internship-block .link-more__angle:before {
		background-color: transparent !important;
	}
	.news-block.internship-block .link-more__angle svg g {
		fill: #000;
	}
}
.news-block.internship-block:hover {
	background-color: #fff;
	border-color: #ccc;
}
.news-block.internship-block:hover .news-block__img, .news-block.internship-block:hover .news-block__text {
	opacity: 1;
}
.news-block.internship-block:hover .news-block__intership {
	color: #000;
	opacity: .12;
}
.news-block.internship-block:hover .news-block__img img {
	-webkit-transform: none;
	    -ms-transform: none;
	        transform: none;
}
@media (min-width: 992px) {
	.col-projects {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 calc(50% - (4.166666666666667% / 2));
		    flex: 0 0 calc(50% - (4.166666666666667% / 2));
		max-width: calc(50% - (4.166666666666667% / 2));
	}
}
.row-news {
	width: 100%;
}
@media (min-width: 1200px) {
	.row-news {
		margin-right: -2.3125rem;
		margin-left: -2.3125rem;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.row-news {
		margin-right: -1.375rem;
		margin-left: -1.375rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.row-news {
		margin-right: -1.0625rem;
		margin-left: -1.0625rem;
	}
}
@media (min-width: 1200px) {
	.col-news {
		padding-right: 2.3125rem;
		padding-left: 2.3125rem;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	.col-news {
		padding-right: 1.375rem;
		padding-left: 1.375rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.col-news {
		padding-right: 1.0625rem;
		padding-left: 1.0625rem;
	}
}
.number-block {
	margin-bottom: 2.5rem;
}
@media (max-width: 767px) {
	.number-block {
		margin-bottom: 1.75rem;
	}
}
.number-block_count {
	position: relative;
}
@media (max-width: 767px) {
	.number-block_count {
		margin-bottom: .75rem;
	}
}
.number-block_count:before {
	display: block;
	counter-increment: dream-list;
	content: counter(dream-list) '';
	        text-stroke: .0625rem rgba(0,0,0,.3);
	-webkit-text-stroke: .0625rem rgba(0,0,0,.3);
	font-weight: 700;
	line-height: .87;
	font-size: 2.4375em;
	color: #fff;
	margin-bottom: .375rem;
}
.row-number-block {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-right: 3.25rem;
	margin-bottom: 4.5rem;
}
@media (max-width: 767px) {
	.row-number-block {
		margin-right: 0;
		margin-bottom: 0;
	}
}
.row-number-block .number-block {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 47.1%;
	    flex: 0 0 47.1%;
	max-width: 47.1%;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.row-number-block .number-block {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 46%;
		    flex: 0 0 46%;
		max-width: 46%;
	}
}
@media (max-width: 767px) {
	.row-number-block .number-block {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
	}
}
.row-number-icon .number-block {
	margin-bottom: 6.25rem;
}
@media (max-width: 767px) {
	.row-number-icon .number-block {
		margin-bottom: 4.75rem;
	}
}
@media (max-width: 767px) {
	.factory-panel {
		display: none;
	}
}
.factory-panel .row {
	margin-bottom: 8.75rem;
}
@media (max-width: 767px) {
	.factory-panel .row {
		margin-bottom: 2.6875rem;
		padding-top: 3.125rem;
	}
}
.factory-panel h2 {
	font-size: 2.125em;
	padding-bottom: 2.5rem;
	margin-bottom: 2.75rem;
}
@media (max-width: 767px) {
	.factory-panel h2 {
		display: none;
	}
}
.factory-panel h2:after {
	width: 100%;
}
.factory-panel__text {
	padding-right: 3.125rem;
	margin-bottom: 4.375rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.factory-panel__text {
		padding-right: 2.5rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.factory-panel__text {
		padding-right: 1.875rem;
	}
}
@media (max-width: 767px) {
	.factory-panel__text {
		padding-right: 0;
		margin-bottom: 2.0625rem;
	}
}
.factory-panel__contacts {
	margin-bottom: 2.5rem;
	padding-right: 3.125rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.factory-panel__contacts {
		padding-right: 2.5rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.factory-panel__contacts {
		padding-right: 1.875rem;
	}
}
@media (max-width: 767px) {
	.factory-panel__contacts {
		padding-right: 0;
	}
}
.factory-panel__contacts a {
	color: #d74d39;
	text-decoration: underline;
}
.factory-panel__contacts a:hover {
	color: #000;
}
.factory-panel__contacts h4 {
	font-size: 1.25em;
	margin-bottom: 1.0625rem;
}
.factory-accordion {
	display: none;
}
@media (max-width: 767px) {
	.factory-accordion {
		display: block;
	}
}
.factory-accordion__heading {
	height: 4.5625rem;
	line-height: 1.31;
	font-size: 1.625em;
	font-weight: 700;
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: .0625rem solid #000;
}
.factory-accordion__heading:after {
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-left: .34375rem solid transparent;
	border-right: .34375rem solid transparent;
	border-top: .34375rem solid #000;
	right: .5rem;
	top: 2.0625rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.factory-accordion.active .factory-accordion__heading:after {
	-webkit-transform: rotate(180deg);
	    -ms-transform: rotate(180deg);
	        transform: rotate(180deg);
}
.management {
	margin-bottom: 4.875rem;
	margin-top: .875rem;
}
@media (max-width: 767px) {
	.management {
		margin-top: 0;
		margin-bottom: 4.375rem;
	}
}
.management__img {
	position: relative;
	height: 18.75rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 2.125rem;
	overflow: hidden;
}
@media (max-width: 767px) {
	.management__img {
		height: 14.25rem;
		margin-bottom: 2rem;
	}
}
.management__img img {
	-o-object-fit: cover;
	   object-fit: cover;
	height: 100%;
}
@media (max-width: 767px) {
	.management__img img {
		height: auto;
		width: 100%;
	}
}
.management__img__cite {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ba0c2f;
	padding: 2.3125rem 1.875rem;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	overflow: hidden;
}
@media (min-width: 768px) and (max-width: 991px) {
	.management__img__cite {
		padding: 1.75rem 1.25rem;
		font-size: .9375em;
	}
}
@media (max-width: 767px) {
	.management__img__cite {
		padding: 1.75rem 1.5625rem;
		font-size: .875em;
	}
}
.management__text h4 {
	font-size: 1.625em;
	line-height: 1.31;
	margin-bottom: .75rem;
}
@media (max-width: 767px) {
	.management__text h4 {
		margin-bottom: .4375rem;
	}
}
.management.to-hover:hover .circle, .management.to-hover:hover .management__img__cite {
	opacity: 1;
	visibility: visible;
	z-index: 10;
}
.management .circle {
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.management__text.index-manager {
	margin-top: .9375rem;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	max-width: 100%;
}
@media (max-width: 767px) {
	.management__text.index-manager {
		margin-bottom: 0;
	}
}
@media (max-width: 767px) {
	.management__text.index-manager p {
		margin-bottom: 0;
	}
}
.management__text.index-manager h4, .management__text.index-manager p {
	color: #b8b8b8;
}
.management__text.index-manager h4 {
	font-size: 1.125em;
	margin-bottom: 0;
}
.row-year {
	position: relative;
	margin-top: 2.375rem;
}
@media (max-width: 767px) {
	.row-year {
		margin-top: 0;
	}
}
.row-year:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	bottom: -8.75rem;
	left: 50%;
	width: .0625rem;
	background-color: #000;
}
@media (min-width: 768px) and (max-width: 991px) {
	.row-year:after {
		left: 0;
	}
}
@media (max-width: 767px) {
	.row-year:after {
		left: 1.6875rem;
		top: 14.125rem;
		bottom: -9.375rem;
	}
}
.year {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-width: calc(51% - .375rem);
	-webkit-box-flex: 0;
	-ms-flex: 0 0 calc(51% - .375rem);
	    flex: 0 0 calc(51% - .375rem);
	position: relative;
	margin-bottom: 11.25rem;
	padding-right: 3.5rem;
	z-index: 100;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.year {
		padding-right: 1.875rem;
		max-width: calc(51% - .25rem);
		-webkit-box-flex: 0;
		-ms-flex: 0 0 calc(51% - .25rem);
		    flex: 0 0 calc(51% - .25rem);
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.year {
		padding-left: 3.125rem;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 85%;
		    flex: 0 0 85%;
		max-width: 85%;
	}
}
@media (max-width: 767px) {
	.year {
		margin-left: 1.6875rem;
		padding-right: 0;
		padding-left: 1.5625rem;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		margin-bottom: 7.5rem;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.year {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
	}
}
@media (max-width: 567px) {
	.year {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.625rem;
		    flex: 0 0 16.625rem;
		max-width: 16.625rem;
	}
}
.year__text {
	position: relative;
	padding-right: 3.125rem;
}
@media (max-width: 767px) {
	.year__text {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2;
		padding-right: 0;
	}
}
.year__text_heading {
	font-size: 3.875em;
	font-weight: bold;
	line-height: 1;
	letter-spacing: .06875rem;
	margin-bottom: 1.375rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.year__text_heading {
		font-size: 3.375em;
	}
}
@media (max-width: 767px) {
	.year__text_heading {
		font-size: 3.125em;
		letter-spacing: .05625rem;
		margin-bottom: .5625rem;
	}
}
.year__text img {
	position: absolute;
	bottom: 0;
	left: 0;
}
@media (max-width: 767px) {
	.year__text img {
		position: static;
		margin-top: .4375rem;
	}
}
.year__img {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 15.625rem;
	    flex: 0 0 15.625rem;
	max-width: 15.625rem;
	height: 15.625rem;
	overflow: hidden;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.year__img {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 14.0625rem;
		    flex: 0 0 14.0625rem;
		max-width: 14.0625rem;
		height: 14.0625rem;
	}
}
@media (max-width: 767px) {
	.year__img {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 12.125rem;
		    flex: 0 0 12.125rem;
		max-width: 12.125rem;
		height: 12.125rem;
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1;
		margin-bottom: 1.75rem;
	}
}
.year__img img {
	-o-object-fit: cover;
	   object-fit: cover;
	width: 100%;
}
.year:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: .6875rem;
	height: .6875rem;
	background-color: #000;
}
@media (max-width: 991px) {
	.year:after {
		right: auto;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.year:after {
		left: -.3125rem;
	}
}
@media (max-width: 767px) {
	.year:after {
		left: -.1875rem;
		width: .4375rem;
		height: .4375rem;
		top: 13.9375rem;
	}
}
.year.reverse .year__text {
	-webkit-box-ordinal-group: 3;
	-ms-flex-order: 2;
	order: 2;
	padding-right: 0;
}
.year.reverse .year__img {
	-webkit-box-ordinal-group: 2;
	-ms-flex-order: 1;
	order: 1;
	margin-right: 3rem;
}
.year.reverse.right .year__text {
	padding-right: 0;
}
.year.center {
	padding-right: 1.875rem;
	margin-left: 12.5%;
	margin-bottom: 15.5625rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.year.center {
		padding-right: .9375rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.year.center {
		margin-left: 0;
	}
}
@media (max-width: 767px) {
	.year.center {
		padding-left: 1.6875rem;
		padding-right: 0;
		margin-left: 1.6875rem;
		margin-bottom: 7.5rem;
	}
}
.year.center:after {
	right: 9.375rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.year.center:after {
		right: 7.5rem;
	}
}
.year.center .year__img {
	top: 4.3125rem;
}
@media (max-width: 767px) {
	.year.center .year__img {
		top: 0;
	}
}
.year.center.right {
	padding-left: 0;
	margin-left: 39.7%;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.year.center.right {
		margin-left: 37.1%;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.year.center.right {
		padding-left: 3.125rem;
		margin-left: 0;
	}
}
@media (max-width: 767px) {
	.year.center.right {
		padding-right: 0;
		padding-left: 1.6875rem;
		margin-left: 1.6875rem;
	}
}
.year.center.right:after {
	left: 7.4375rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.year.center.right:after {
		left: -.3125rem;
	}
}
@media (max-width: 767px) {
	.year.center.right:after {
		left: -.1875rem;
	}
}
.year.center.right .year__img {
	margin-right: 7.4375rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.year.center.right .year__img {
		margin-right: 4.3125rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.year.center.right .year__img {
		margin-right: 4.3125rem;
	}
}
.year.center.right .year__text {
	padding-right: 0;
}
.year.right {
	margin-left: 50%;
	padding-right: 0;
	padding-left: 6rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.year.right {
		padding-left: 1.875rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.year.right {
		margin-left: 0;
		padding-left: 3.125rem;
	}
}
@media (max-width: 767px) {
	.year.right {
		padding-left: 1.6875rem;
		margin-left: 1.5625rem;
	}
}
.year.right:after {
	right: auto;
	left: -.3125rem;
}
@media (max-width: 767px) {
	.year.right:after {
		left: -.0625rem;
	}
}
.year.right.sticky {
	padding-left: 0;
}
@media (max-width: 767px) {
	.year.right.sticky {
		padding-left: 1.6875rem;
	}
}
.year.double {
	max-width: calc(50% - .063rem);
	-webkit-box-flex: 0;
	-ms-flex: 0 0 calc(50% - .063rem);
	    flex: 0 0 calc(50% - .063rem);
	margin-bottom: 15rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.year.double {
		padding-left: 3.125rem;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 85%;
		    flex: 0 0 85%;
		max-width: 85%;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.year.double {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
	}
}
@media (max-width: 567px) {
	.year.double {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.625rem;
		    flex: 0 0 16.625rem;
		max-width: 16.625rem;
	}
}
.year.double.right {
	margin-left: 0;
}
.year.sticky {
	padding-right: .3125rem;
}
@media (max-width: 767px) {
	.year.sticky {
		padding-left: 1.6875rem;
		padding-right: 0;
		margin-left: 1.6875rem;
	}
}
@media (max-width: 767px) {
	.year.sticky.right {
		margin-left: 1.5625rem;
	}
}
.year.sticky.double {
	padding-right: 0;
	padding-left: 0;
	position: relative;
	right: -.125rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.year.sticky.double {
		padding-right: .0625rem;
	}
}
@media (max-width: 991px) {
	.year.sticky.double {
		right: auto;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.year.sticky.double {
		padding-left: 3.125rem;
	}
}
@media (max-width: 767px) {
	.year.sticky.double {
		padding-left: 1.6875rem;
		margin-left: 1.5625rem;
		margin-bottom: 7.5rem;
	}
}
.year.sticky.double:after {
	right: -.3125rem;
}
.year.sticky.double.right {
	padding-right: 0;
	padding-left: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
	.year.sticky.double.right {
		padding-left: 3.125rem;
	}
}
@media (max-width: 767px) {
	.year.sticky.double.right {
		padding-left: 1.6875rem;
		margin-left: 1.5625rem;
		margin-bottom: 7.5rem;
	}
}
.year.sticky.double.right .year__img {
	top: 3.75rem;
}
@media (max-width: 767px) {
	.year.sticky.double.right .year__img {
		top: 0;
	}
}
.year.sticky.double.right:after {
	display: none;
}
@media (max-width: 991px) {
	.year.sticky.double.right:after {
		display: block;
	}
}
.year-last {
	text-align: center;
	position: relative;
	padding-top: 3.75rem;
}
@media (max-width: 991px) {
	.year-last {
		text-align: left;
	}
}
@media (max-width: 767px) {
	.year-last {
		padding-left: 1.6875rem;
		padding-top: 1.0625rem;
		margin-left: 1.6875rem;
	}
}
.year-last .year__text_heading {
	margin-bottom: .375rem;
}
.year-last img {
	margin-top: 2.5rem;
	max-width: 100%;
}
@media (max-width: 767px) {
	.year-last img {
		margin-top: .5625rem;
	}
}
.year-last:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: calc(50% - .375rem);
	width: .6875rem;
	height: .6875rem;
	background-color: #000;
}
@media (max-width: 991px) {
	.year-last:after {
		right: auto;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.year-last:after {
		left: -.3125rem;
	}
}
@media (max-width: 767px) {
	.year-last:after {
		width: .4375rem;
		height: .4375rem;
		left: -.1875rem;
		top: 1.0625rem;
	}
}
.circle {
	width: 10rem;
	height: 10rem;
	background-color: transparent;
	border: .0625rem solid #000;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9990;
	opacity: 0;
	-webkit-transition: .2s opacity ease;
	     -o-transition: .2s opacity ease;
	        transition: .2s opacity ease;
}
.circle-hover {
	position: relative;
}
.circle-hover:hover .circle {
	opacity: .16;
}
.ethic-block {
	display: block;
	position: relative;
	z-index: 10;
}
@media (max-width: 991px) {
	.ethic-block {
		margin-bottom: .625rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.ethic-block {
		margin-right: .625rem;
	}
}
.ethic-block__img {
	overflow: hidden;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 17.9375rem;
	position: relative;
}
@media (max-width: 767px) {
	.ethic-block__img {
		height: 15.9375rem;
	}
}
.ethic-block__img:before, .ethic-block__img:after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.ethic-block__img:after {
	background-color: #c20027;
	opacity: 0;
}
.ethic-block__img:before {
	content: '';
	display: block;
	position: absolute;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,.5)));
	background-image: -webkit-linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,.5));
	background-image:      -o-linear-gradient(top, rgba(0,0,0,0), rgba(0,0,0,.5));
	background-image:         linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.5));
}
.ethic-block__img img {
	-o-object-fit: cover;
	   object-fit: cover;
	width: 100%;
	height: 100%;
}
.ethic-block__text {
	position: absolute;
	bottom: 1.8125rem;
	right: 1.8125rem;
	left: 1.8125rem;
	font-size: 1.125em;
	font-weight: bold;
	line-height: 1.44;
	color: #fff;
	z-index: 100;
}
@media (max-width: 767px) {
	.ethic-block__text {
		line-height: 1.63;
		font-size: 1em;
		bottom: 1.5625rem;
		right: 1.5625rem;
		left: 1.5625rem;
	}
}
.ethic-block:hover .ethic-block__text {
	text-decoration: underline;
}
.ethic-block:hover .ethic-block__img img {
	-webkit-filter: grayscale(100%);
	        filter: grayscale(100%);
}
.ethic-block:hover .ethic-block__img:before {
	opacity: 0;
}
.ethic-block:hover .ethic-block__img:after {
	opacity: .7;
}
.row-ethic {
	padding-top: 5.875rem;
	padding-bottom: 1.5625rem;
}
@media (max-width: 767px) {
	.row-ethic {
		padding-top: 4.625rem;
		padding-bottom: 3.75rem;
	}
}
.pdf-block {
	display: block;
	margin-right: 1.3125rem;
	background-color: #f4f4f5;
	position: relative;
	height: 17rem;
	margin-bottom: 6.25rem;
	-webkit-clip-path: polygon(87% 0%, 100% 13%, 100% 100%, 0 100%, 0 0);
	        clip-path: polygon(87% 0%, 100% 13%, 100% 100%, 0 100%, 0 0);
	padding: 1.6875rem 1.875rem 3.75rem;
	max-width: 17.4375rem;
	width: 100%;
}
@media (max-width: 767px) {
	.pdf-block {
		margin-right: 0;
		padding-right: 1.5625rem;
		padding-left: 1.5625rem;
		padding-top: 1.75rem;
		margin-bottom: 1.875rem;
		max-width: 100%;
	}
}
.pdf-block__heading {
	-webkit-text-stroke: .1rem #000;
	        text-stroke: .1rem #000;
	font-size: 2.375em;
	font-weight: 700;
	ine-height: .89;
	color: rgba(0,0,0,0);
	opacity: .2;
	margin-bottom: .75rem;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.pdf-block__heading {
		margin-bottom: .6875rem;
	}
}
.pdf-block__text {
	font-size: 1em;
	font-weight: bold;
	line-height: 1.63;
	color: #000;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.pdf-block .link-more {
	position: absolute;
	bottom: 0;
	right: 1.875rem;
	left: 1.875rem;
	border-top: .0625rem solid #000;
	margin-bottom: 1.25rem;
	padding-top: 1.1875rem;
}
@media (max-width: 767px) {
	.pdf-block .link-more {
		right: 1.625rem;
		left: 1.625rem;
		font-size: .9375em;
	}
}
.pdf-block .link-more .link-more_text {
	color: rgba(0,0,0,.3);
}
.pdf-block .link-more .link-more__angle {
	top: .875rem;
	right: 2.75rem;
}
@media (max-width: 767px) {
	.pdf-block .link-more .link-more__angle {
		right: 1.75rem;
		width: 1.625rem;
		height: 1.625rem;
	}
}
.pdf-block .link-more:hover .link-more_text {
	color: #fff;
}
.pdf-block:hover {
	background-color: #ba0c2f;
}
.pdf-block:hover .pdf-block__heading {
	color: #fff;
	-webkit-text-stroke: .0625rem #fff;
	        text-stroke: #fff;
}
.pdf-block:hover .pdf-block__text {
	color: #fff;
}
.pdf-block:hover .link-more {
	border-color: #fff;
}
.pdf-block:hover .link-more .link-more_text {
	color: #fff;
}
.pdf-block:hover .link-more .link-more__angle svg g {
	fill: #ba0c2f;
}
.pdf-block:hover .link-more .link-more__angle:before {
	background-color: #fff;
	border-color: #fff;
}
.row-pdf {
	padding-top: 7.125rem;
}
@media (max-width: 767px) {
	.row-pdf {
		padding-top: 4.625rem;
		margin-bottom: 5rem;
	}
}
.pdf-list-article {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1.25rem;
	padding-bottom: 1rem;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}
@media (max-width: 767px) {
	.pdf-list-article {
		padding-bottom: 3.4375rem;
	}
}
.aside-links {
	padding-top: .1875rem;
}
.aside-links li {
	margin-bottom: .9375rem;
}
.aside-links li a {
	font-size: .875em;
	line-height: 1.375rem;
	color: rgba(0,0,0,.3);
	position: relative;
	display: inline-block;
}
.aside-links li a:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	width: 3.125rem;
	height: .0625rem;
	background-color: #000;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}
.aside-links li a:hover {
	color: #000;
	text-decoration: underline;
}
.aside-links li a.active {
	padding-bottom: 1.125rem;
	margin-bottom: 1.125rem;
	color: #000;
}
.aside-links li a.active:after {
	opacity: 1;
	visibility: visible;
	z-index: 10;
}
.aside-links li a.active:hover {
	text-decoration: none;
}
@media (max-width: 767px) {
	.aside-article__block h2 {
		padding-right: 1.875rem;
		outline: none;
		line-height: 1.625rem;
	}
	.aside-article__block h2:before {
		content: '';
		display: block;
		position: absolute;
		width: 0;
		height: 0;
		border-left: .3125rem solid transparent;
		border-right: .3125rem solid transparent;
		border-top: .3125rem solid #000;
		right: .5rem;
		top: .875rem;
		-webkit-transition: all .2s ease;
		     -o-transition: all .2s ease;
		        transition: all .2s ease;
	}
	.aside-article__block h2.active:before, .aside-article__block h2.ui-state-active:before {
		-webkit-transform: rotate(180deg);
		    -ms-transform: rotate(180deg);
		        transform: rotate(180deg);
	}
}
.aside-article-responsibility .aside-article__block__panel {
	padding-bottom: 6.25rem;
}
@media (min-width: 1200px) {
	.aside-article-responsibility .aside-article__block__panel {
		padding-right: 12.5rem;
	}
}
.career-count {
	font-size: 1.375em;
	line-height: 1.55;
	color: rgba(0,0,0,.2);
	margin-bottom: 1.125rem;
}
@media (max-width: 767px) {
	.career-count {
		margin-left: 8.333333333333334%;
		font-size: 1.125em;
		line-height: 1.86;
		margin-bottom: .6875rem;
	}
}
.career-block {
	margin-bottom: 5.625rem;
}
.career-block__heading {
	line-height: 1.7;
	font-weight: 700;
	font-size: 1.25em;
	background-color: #f4f4f5;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-top: 1.4375rem;
	padding-bottom: 1.4375rem;
}
@media (max-width: 767px) {
	.career-block__heading {
		padding-left: 8.333333333333334%;
		padding-right: 8.333333333333334%;
		font-size: 1.125em;
		line-height: 1.44;
		text-align: center;
		padding-top: 1.3125rem;
		padding-bottom: 1.3125rem;
	}
}
.career-block__link {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: .0625rem solid #ccc;
	padding-top: 1.0625rem;
	padding-bottom: .9375rem;
}
@media (max-width: 767px) {
	.career-block__link {
		padding-left: 8.333333333333334%;
		padding-right: 8.333333333333334%;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
		padding-top: 1.25rem;
		padding-bottom: 1.3125rem;
	}
}
.career-block__link__heading {
	padding-left: 1.5625rem;
}
@media (max-width: 767px) {
	.career-block__link__heading {
		padding-left: 0;
		margin-bottom: .4375rem;
	}
}
.career-block__link__heading p {
	line-height: 1.81;
	margin-bottom: 0;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	opacity: .34;
}
@media (max-width: 767px) {
	.career-block__link__heading p {
		line-height: 1.38;
	}
}
.career-block__link__heading p.text-bold {
	font-size: 1em;
	opacity: 1;
}
@media (max-width: 767px) {
	.career-block__link__heading p.text-bold {
		margin-bottom: .8125rem;
	}
}
.career-block__link__money, .career-block__link__city, .career-block__link__more {
	color: #000;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (max-width: 767px) {
	.career-block__link__money {
		margin-bottom: .1875rem;
	}
}
.career-block__link__money, .career-block__link__city {
	font-size: .875em;
}
@media (max-width: 767px) {
	.career-block__link .link-more {
		margin-top: 0;
		top: -.5625rem;
		float: right;
	}
}
@media (max-width: 767px) {
	.career-block__link .link-more_text {
		opacity: 0;
		visibility: hidden;
		z-index: -1;
	}
}
.career-block__link .link-more:hover .link-more__angle:before {
	background-color: #fff;
}
.career-block__link .link-more:hover .link-more__angle svg path {
	fill: #ba0c2f !important;
}
.career-block__link:hover {
	background-color: #ba0c2f;
}
.career-block__link:hover .career-block__link__heading, .career-block__link:hover .career-block__link__money, .career-block__link:hover .career-block__link__city {
	color: #fff;
}
.career-block__link:hover .career-block__link__heading p, .career-block__link:hover .career-block__link__money p, .career-block__link:hover .career-block__link__city p {
	color: #fff;
}
.career-block__link:hover .career-block__link__more .link-more_text {
	color: #fff;
}
.career-block__link:hover .career-block__link__more .link-more__angle:before {
	border-color: #fff;
}
.career-block__link:hover .career-block__link__more .link-more__angle svg path {
	fill: #fff;
}
.global-block {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	margin-bottom: 8.75rem;
}
@media (max-width: 767px) {
	.global-block {
		margin-bottom: 5rem;
	}
}
.global-block__img {
	height: 21.875rem;
	overflow: hidden;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.global-block__img {
		height: 18.75rem;
	}
}
@media (max-width: 767px) {
	.global-block__img {
		height: 16.625rem;
	}
}
.global-block__img img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	   object-fit: cover;
}
.global-block__heading {
	border-bottom: .0625rem solid #000;
	padding-top: 2.875rem;
	padding-right: .9375rem;
}
@media (max-width: 767px) {
	.global-block__heading {
		border: none;
		padding-top: 2.5625rem;
	}
}
.global-block__heading_type {
	opacity: .3;
	font-size: .875em;
	line-height: 2.43;
	position: absolute;
	bottom: 0;
	left: 0;
	margin-bottom: 2.1875rem;
}
@media (max-width: 767px) {
	.global-block__heading_type {
		bottom: auto;
		top: 0;
	}
}
.global-block__heading h4 {
	font-size: 1.75em;
	line-height: 1.29;
}
@media (max-width: 767px) {
	.global-block__heading h4 {
		line-height: 1.3;
		font-size: 1.25em;
		margin-bottom: 1.0625rem;
	}
}
.global-block__text {
	border-bottom: .0625rem solid #000;
	padding-top: 2.9375rem;
}
@media (max-width: 767px) {
	.global-block__text {
		padding-top: 0;
		padding-bottom: .6875rem;
		margin-bottom: 2.9375rem;
	}
}
.global-block__text .link-more {
	position: absolute;
	left: 0;
	bottom: 0;
	margin-bottom: 2.6875rem;
}
@media (max-width: 767px) {
	.global-block__text .link-more {
		bottom: -3.4375rem;
		margin-bottom: 0;
	}
}
.global-block:hover .global-block__img {
	opacity: .7;
}
.global-block:hover .global-block__text .link-more {
	color: #000;
}
.global-block:hover .global-block__heading_type {
	color: #000;
}
.global-block:hover .global-block__heading h4 {
	text-decoration: underline;
}
.gate {
	max-width: 56.25rem;
	width: 100%;
	height: 36.25rem;
	position: fixed;
	top: calc(50% - 16.4375rem);
	left: calc(50% - 28.125rem);
	z-index: 9999;
	background-color: #fff;
	padding-top: 1.125rem;
	padding-left: 3.125rem;
	padding-right: 3.125rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	    flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}
@media (min-width: 768px) and (max-width: 991px) {
	.gate {
		left: calc(50% - 24rem);
		padding-left: 1.125rem;
		padding-right: 1.125rem;
		height: 100%;
		overflow-y: scroll;
		top: 0;
	}
}
@media (min-width: 568px) and (max-width: 767px) {
	.gate {
		overflow-y: scroll;
		height: 100%;
	}
}
@media (max-width: 767px) {
	.gate {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 1.125rem 1.6875rem;
	}
}
.gate.active {
	opacity: 1;
	visibility: visible;
	z-index: 9998;
}
.gate__logo {
	display: inline-block;
	max-width: 11.1875rem;
	margin-bottom: 1.25rem;
}
.gate__logo svg {
	width: 100%;
	height: auto;
}
.gate__adult {
	line-height: 1.71;
	font-size: .875em;
	color: rgba(0,0,0,.3);
	margin-bottom: 1.3125rem;
}
@media (max-width: 767px) {
	.gate__adult {
		font-size: .75em;
		line-height: 1.5;
	}
}
.gate__adult a {
	color: rgba(0,0,0,.3);
}
.gate__adult-sm {
	font-size: .6875em;
	margin-bottom: .0625rem;
}
.gate__adult-big {
	opacity: .2;
	font-size: 1.3125em;
	letter-spacing: -.00625rem;
	margin-bottom: .5rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.gate__adult-big {
		font-size: 1.125em;
	}
}
@media (max-width: 767px) {
	.gate__adult-big {
		line-height: 1.53;
		font-size: .8125em;
		text-align: left;
	}
}
.gate__list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 4.6875rem;
}
.gate__list li {
	margin: 0 .1875rem;
	max-width: 7.625rem;
	width: 100%;
}
.gate__list li .btn {
	height: 3.125rem;
}
.gate_hr {
	background-color: rgba(0,0,0,.2);
	margin: 0 0 .875rem;
}
@media (max-width: 767px) {
	.gate_hr {
		margin: .75rem 0;
	}
}
.gate__steps {
	min-height: 12.1875rem;
}
@media (min-width: 568px) and (max-width: 767px) {
	.gate__steps {
		min-height: 14.375rem;
	}
}
.gate__step {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.gate__step.active {
	opacity: 1;
	visibility: visible;
	z-index: 10;
}
.gate__country {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 1.875rem;
}
@media (max-width: 767px) {
	.gate__country {
		margin-bottom: 1.25rem;
	}
}
.gate__country li {
	max-width: 12.5rem;
	width: 100%;
}
.gate .nav-lang {
	margin-top: .375rem;
}
.gate h3 {
	font-size: 1.5em;
	line-height: 1.3;
	text-align: center;
	margin-bottom: .1875rem;
	max-width: 21.875rem;
	margin-left: auto;
	margin-right: auto;
}
@media (max-width: 767px) {
	.gate h3 {
		font-size: 1.25em;
	}
}
.gate h3.text-red {
	margin-bottom: .8125rem;
}
@media (max-width: 767px) {
	.gate h3.text-red {
		font-size: 1em;
	}
}
.gate .radiocheck {
	margin-bottom: .625rem;
}
.gate .btn {
	position: relative;
	z-index: 9999;
}
#btn-step1-no {
	background-color: #1e376d;
}
.form-gate {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	max-width: 25.1875rem;
	margin: 0 auto;
}
@media (max-width: 767px) {
	.form-gate {
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
	}
}
.form-gate input {
	background-color: #f4f4f5;
	border-bottom: .0625rem solid #ccc;
	text-align: center;
	margin: 0 .09375rem;
	padding-right: 0;
	padding-bottom: 0;
	padding-top: .0625rem;
	font-size: .875em;
	height: 3.125rem;
}
@media (max-width: 767px) {
	.form-gate input {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		    flex: 0 0 25%;
		max-width: 25%;
		margin-bottom: 1.25rem;
	}
}
.form-gate input:focus {
	border-color: #000;
}
.form-gate input.input-year {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 6.4375rem;
	    flex: 0 0 6.4375rem;
	max-width: 6.4375rem;
}
@media (max-width: 767px) {
	.form-gate input.input-year {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 46%;
		    flex: 0 0 46%;
		max-width: 46%;
	}
}
.form-gate button {
	height: 3.125rem;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 8.75rem;
	    flex: 0 0 8.75rem;
	max-width: 8.75rem;
}
@media (max-width: 767px) {
	.form-gate button {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		    flex: 0 0 100%;
		max-width: 100%;
	}
}
.form-gate button.disabled {
	opacity: .4;
	pointer-events: none;
}
.stats {
	position: relative;
}
@media (max-width: 767px) {
	.stats {
		margin-top: 2.1875rem;
	}
}
.stats:after {
	content: '';
	display: block;
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	height: .0625rem;
	background-color: #fff;
}
@media (max-width: 767px) {
	.stats:after {
		bottom: 1.375rem;
	}
}
.stats_col {
	font-size: .875em;
	line-height: 1.86;
	padding-top: .6875rem;
	padding-bottom: .625rem;
}
@media (max-width: 767px) {
	.stats_col {
		border-bottom: .0625rem solid rgba(0,0,0,.3);
		padding-top: 0;
		padding-bottom: 1.25rem;
		margin-bottom: 1.375rem;
	}
}
.stats_col a {
	color: #d74d39;
	text-decoration: underline;
}
.stats_heading {
	font-weight: 700;
	border-bottom: .0625rem solid rgba(0,0,0,.3);
	padding-right: .9375rem;
}
@media (max-width: 767px) {
	.stats_heading {
		border-bottom: none;
		padding-bottom: .625rem;
		font-size: 1em;
		margin-bottom: 0;
	}
}
@media (max-width: 767px) {
	.stats_heading-mob {
		border-bottom: .0625rem solid rgba(0,0,0,.3);
		padding-bottom: 1.25rem;
		margin-bottom: 1.375rem;
	}
}
.stats_big {
	font-size: 1.25em;
	line-height: 1.3;
}
@media (min-width: 768px) and (max-width: 991px) {
	.stats-mid {
		margin-bottom: 5rem;
	}
}
@media (max-width: 767px) {
	.stats-mid {
		margin-bottom: 4.1875rem;
	}
}
@media (max-width: 767px) {
	.stats-mid {
		margin-top: .9375rem;
	}
}
.stats-mid .stats_heading {
	font-size: 1em;
	line-height: 1.63;
}
.tab-links {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 7.375rem;
}
@media (max-width: 767px) {
	.tab-links::-webkit-scrollbar {
		width: 0 !important;
	}
}
@media (max-width: 767px) {
	.tab-links {
		margin-bottom: 3.125rem;
		overflow-x: scroll;
		padding-left: 8.333333333333334%;
		overflow: -moz-scrollbars-none;
		-ms-overflow-style: none;
		position: relative;
	}
}
.tab-links li {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 100%;
	    flex: 1 1 100%;
	max-width: 100%;
}
.tab-links li a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 3.375rem;
	font-size: .9375em;
	font-weight: 700;
	color: #000;
	border-top: .0625rem solid #000;
	border-bottom: .0625rem solid #000;
	padding-left: .3125rem;
	padding-right: .3125rem;
	text-align: center;
}
@media (max-width: 767px) {
	.tab-links li a {
		height: 3.5rem;
		width: 11.5625rem;
		font-size: .875em;
		position: relative;
	}
	.tab-links li a:after {
		content: '';
		display: block;
		position: absolute;
		right: 0;
		width: .0625rem;
		background-color: #000;
		top: 0;
		bottom: 0;
	}
}
.tab-links li a.active, .tab-links li a:hover {
	border-color: #ba0c2f;
	background-color: #ba0c2f;
	color: #fff;
}
.tab-links li a.active:after {
	display: none;
}
.tab-links:hover li a.active {
	color: rgba(0,0,0,.3);
	background-color: transparent;
	border-color: #000;
}
.tab-links:hover li a.active:hover {
	border-color: #ba0c2f;
	background-color: #ba0c2f;
	color: #fff;
}
.tab-links-brands {
	margin-bottom: 2.1875rem;
}
.share-block {
	padding-top: 5.4375rem;
	position: relative;
}
@media (max-width: 767px) {
	.share-block {
		padding-top: 5.8125rem;
	}
}
.share-block_success {
	position: absolute;
	left: 0;
	right: 0;
	bottom: -1.375rem;
	text-align: center;
	font-size: .75em;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}
.share-block_success.visible {
	opacity: 1;
	visibility: visible;
	z-index: 10;
}
.share-block_text {
	color: rgba(0,0,0,.4);
	font-size: .875em;
	margin-bottom: .9375rem;
}
.share-block__list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border: .0625rem solid #ccc;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
@media (max-width: 767px) {
	.share-block__list {
		border-bottom: 0;
		border-right: 0;
	}
}
.share-block__list li {
	padding-left: 0 !important;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 100%;
	    flex: 1 1 100%;
	max-width: 100%;
	margin-bottom: 0 !important;
	border-right: .0625rem solid #ccc;
}
@media (max-width: 767px) {
	.share-block__list li {
		border-bottom: .0625rem solid #ccc;
	}
}
.share-block__list li:last-child {
	border: none;
}
@media (max-width: 767px) {
	.share-block__list li:last-child {
		border-right: .0625rem solid #ccc;
		border-bottom: .0625rem solid #ccc;
	}
}
.share-block__list li:before {
	display: none;
}
.share-block__list li a {
	display: block;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 3.375rem;
	position: relative;
}
.share-block__list li a:after {
	content: '';
	display: block;
	position: absolute;
	bottom: -.0625rem;
	left: -.0625rem;
	right: -.0625rem;
	height: .0625rem;
	background-color: #000;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	opacity: 0;
}
.share-block__list li a svg {
	width: 1.1875rem;
	height: auto;
}
.share-block__list li a svg g, .share-block__list li a svg path, .share-block__list li a svg circle, .share-block__list li a svg polygon {
	fill: rgba(0,0,0,.3);
}
.share-block__list li a:hover {
	background-color: rgba(0,0,0,.1);
}
.share-block__list li a:hover:after {
	opacity: 1;
}
.share-block__list li a:hover svg g, .share-block__list li a:hover svg path, .share-block__list li a:hover svg circle, .share-block__list li a:hover svg polygon {
	fill: #000;
}
.quote-block {
	margin-bottom: 3.6875rem;
}
@media (max-width: 767px) {
	.quote-block {
		margin-bottom: 6.875rem;
	}
}
.quote-block img {
	display: inline-block;
	margin-bottom: 2.1875rem;
	width: 100%;
}
@media (max-width: 767px) {
	.quote-block img {
		margin-bottom: 2rem;
	}
}
.quote-block h5 {
	font-size: 1.625em;
	margin-bottom: .25rem;
}
@media (max-width: 767px) {
	.quote-block h5 {
		line-height: 1.55;
		font-size: 1.375em;
		margin-bottom: .1875rem;
	}
}
.quote-block hr {
	margin-top: 1.25rem;
	margin-bottom: 3.75rem;
	position: relative;
}
@media (max-width: 767px) {
	.quote-block hr {
		margin-bottom: 3.1875rem;
	}
}
.quote-block hr:after {
	display: block;
	position: absolute;
	content: '"';
	bottom: -4.25rem;
	left: 0;
	font-size: 3.5em;
	line-height: 1;
}
.col-quote:nth-child(3n+3) {
	margin-top: 3.125rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.col-quote:nth-child(3n+3) {
		margin-top: 0;
	}
}
@media (max-width: 767px) {
	.col-quote:nth-child(3n+3) {
		margin-top: 0;
	}
}
.col-quote:nth-child(3n+1) {
	margin-top: -3.1875rem;
}
@media (min-width: 768px) and (max-width: 991px) {
	.col-quote:nth-child(3n+1) {
		margin-top: 0;
	}
}
@media (max-width: 767px) {
	.col-quote:nth-child(3n+1) {
		margin-top: 0;
	}
}
@media (max-width: 767px) {
	.col-quote:last-child .quote-block {
		margin-bottom: 0;
	}
}
.img-quote {
	margin-top: 3.125rem;
	display: inline-block;
	max-width: 12.4375rem;
}
@media (max-width: 767px) {
	.img-quote {
		display: none;
	}
}
.row-quote {
	padding-top: 4.4375rem;
	padding-bottom: 4.25rem;
}
@media (max-width: 767px) {
	.row-quote {
		padding-top: 0;
		padding-bottom: 5.25rem;
	}
}
.brand-block {
	max-width: 17.5rem;
	width: 100%;
	height: 24.375rem;
	position: relative;
	z-index: 10;
	margin-bottom: 5rem;
	padding-top: 1.875rem;
	margin-right: 1.25rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
	.brand-block {
		max-width: 18.75rem;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.brand-block {
		max-width: 21.25rem;
	}
}
@media (max-width: 767px) {
	.brand-block {
		max-width: 100%;
		margin-bottom: 1.875rem;
		margin-right: 0;
	}
}
.brand-block:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #f4f4f5;
	z-index: -1;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
	border: .0625rem solid #f4f4f5;
}
.brand-block__heading {
	font-size: 1em;
	font-weight: bold;
	line-height: 1.25;
	text-align: center;
	color: #000;
}
.brand-block__img {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	height: 16.875rem;
	margin-bottom: 1.6875rem;
}
.brand-block__img img {
	max-width: 100%;
	max-height: 16.875rem;
}
.brand-block_flag {
	width: 3.125rem;
	position: absolute;
	left: 1.375rem;
	top: 2.5rem;
}
.brand-block:hover:after {
	background-color: #fff;
	border-color: #ccc;
	top: -.375rem;
	right: -.5rem;
	left: -.5rem;
	bottom: -.375rem;
}
.brand-block:hover .brand-block__heading {
	text-decoration: underline;
}
.warning {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 15vh;
	z-index: 9991;
	background-color: #fff;
	padding-right: .5625rem;
	padding-left: .5625rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@media (min-width: 1200px) {
	.warning {
		padding-top: .3125rem;
		padding-bottom: .3125rem;
	}
}
.warning img {
	width: 100%;
	max-height: 100%;
}
@media (max-width: 567px) {
	.warning-d {
		display: none;
	}
}
.warning-m {
	display: none;
}
@media (max-width: 567px) {
	.warning-m {
		display: block;
	}
}
.fishka {
	position: fixed;
	bottom: 1.3125rem;
	right: 6.25rem;
	width: 29.75rem;
	z-index: 9991;
}
.fishka.ukrainian {
	margin-bottom: 15vh;
}
@media (max-width: 992px) {
	.fishka {
		display: none !important;
	}
}
.fishka p.desc {
	font-size: 1.125rem;
	text-align: right;
	color: #fff;
	margin-bottom: .625rem;
}
.fishka .audio-player-wrapp {
	position: relative;
}
@media (min-width: 1200px) {
	.fishka .audio-player-wrapp {
		height: 4.375rem;
	}
}
.fishka .open-player {
	display: none;
}
.fishka .close-player {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 9999;
}
.fishka .close-player a {
	height: 100%;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0 .5625rem;
	position: relative;
}
.fishka .close-player a:after {
	content: '';
	position: absolute;
	right: 0;
	top: .75rem;
	bottom: .75rem;
	width: .0625rem;
	background: rgba(255,255,255,.12);
}
.fishka .close-player a:hover {
	background: rgba(255,255,255,.12);
}
.fishka .close-player a:hover:after {
	opacity: 0;
}
.fishka .close-player a:hover img {
	-webkit-transform: translateX(.1875rem);
	    -ms-transform: translateX(.1875rem);
	        transform: translateX(.1875rem);
	-webkit-transition: .2s ease-in-out all;
	     -o-transition: .2s ease-in-out all;
	        transition: .2s ease-in-out all;
}
.fishka .close-player a img {
	width: .625rem;
	-webkit-transition: .2s ease-in-out all;
	     -o-transition: .2s ease-in-out all;
	        transition: .2s ease-in-out all;
}
.fishka .open-player {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 9999;
}
.fishka .open-player a {
	height: 100%;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 0 .5625rem;
	position: relative;
}
.fishka .open-player a:after {
	content: '';
	position: absolute;
	right: 0;
	top: .75rem;
	bottom: .75rem;
	width: .0625rem;
	background: rgba(255,255,255,.12);
}
.fishka .open-player a:hover {
	background: rgba(255,255,255,.12);
}
.fishka .open-player a:hover:after {
	opacity: 0;
}
.fishka .open-player a:hover img {
	-webkit-transform: translateX(-.1875rem);
	    -ms-transform: translateX(-.1875rem);
	        transform: translateX(-.1875rem);
	-webkit-transition: .2s ease-in-out all;
	     -o-transition: .2s ease-in-out all;
	        transition: .2s ease-in-out all;
}
.fishka .open-player a img {
	width: .625rem;
	-webkit-transition: .2s ease-in-out all;
	     -o-transition: .2s ease-in-out all;
	        transition: .2s ease-in-out all;
}
.fishka .mejs__container {
	width: 29.75rem;
	height: 4.375rem !important;
	position: relative;
	padding-left: 1.375rem;
}
.fishka .mejs__inner {
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
@media (min-width: 1200px) {
	.fishka .mejs__inner {
		height: 4.375rem;
	}
}
.fishka .mejs__controls {
	position: static;
	height: 3.125rem;
	padding: 0;
}
.fishka .mejs__container.mejs__audio .mejs__playlist-layer, .fishka .mejs-container.mejs-audio .mejs-playlist-layer {
	display: none;
}
.fishka .mejs__container.mejs__audio, .fishka .mejs-container.mejs-audio {
	min-height: .0625rem !important;
}
.fishka .mejs__button.mejs__playpause-button.mejs__play {
	width: 3.25rem;
	height: 3.125rem;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-ordinal-group: 3;
	-ms-flex-order: 2;
	order: 2;
}
.fishka .mejs__button.mejs__playpause-button.mejs__play button {
	width: 3.125rem;
	height: 3.125rem;
	margin: 0;
	background: url('../img/fishka/play.svg') no-repeat center center;
	background-size: cover;
	outline: none;
}
.fishka .mejs__button.mejs__playpause-button.mejs__play:hover button {
	background: url('../img/fishka/play.svg') no-repeat center center;
	background-size: cover;
}
.fishka .mejs__button.mejs__playpause-button.mejs__pause {
	width: 3.125rem;
	height: 3.125rem;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-ordinal-group: 3;
	-ms-flex-order: 2;
	order: 2;
}
.fishka .mejs__button.mejs__playpause-button.mejs__pause button {
	width: 3.125rem;
	height: 3.125rem;
	margin: 0;
	background: url('../img/fishka/pause.svg') no-repeat center center;
	background-size: cover;
	outline: none;
}
.fishka .mejs__button.mejs__playpause-button.mejs__pause:hover button {
	background: url('../img/fishka/pause.svg') no-repeat center center;
	background-size: cover;
}
.fishka .mejs__button.mejs__prev-button {
	width: .625rem;
	height: 3.125rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-right: .8125rem;
	-webkit-box-ordinal-group: 2;
	-ms-flex-order: 1;
	order: 1;
}
.fishka .mejs__button.mejs__prev-button button {
	width: .625rem;
	height: .75rem;
	margin: 0;
	background: url('../img/fishka/prev_mus.svg') no-repeat center center;
	background-size: cover;
	outline: none;
}
.fishka .mejs__button.mejs__prev-button:hover button {
	background: url('../img/fishka/prev_mus.svg') no-repeat center center;
	background-size: cover;
}
.fishka .mejs__button.mejs__next-button {
	width: .625rem;
	height: 3.125rem;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-left: .8125rem;
	-webkit-box-ordinal-group: 4;
	-ms-flex-order: 3;
	order: 3;
}
.fishka .mejs__button.mejs__next-button button {
	width: .625rem;
	height: .75rem;
	margin: 0;
	background: url('../img/fishka/next_mus.svg') no-repeat center center;
	background-size: cover;
	outline: none;
}
.fishka .mejs__button.mejs__next-button:hover button {
	background: url('../img/fishka/next_mus.svg') no-repeat center center;
	background-size: cover;
}
.fishka .mejs__time-total {
	height: .0625rem;
	margin-top: 0;
}
.fishka .mejs__time-buffering {
	height: .0625rem;
}
.fishka .mejs__time-loaded {
	height: .0625rem;
}
.fishka .mejs__time-current {
	height: .0625rem;
}
.fishka .mejs__time-hovered {
	height: .0625rem;
}
.fishka .mejs__time-rail {
	-webkit-box-ordinal-group: 1;
	-ms-flex-order: 0;
	order: 0;
	max-width: 11.6875rem !important;
	height: 3.125rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	padding-bottom: .9375rem;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-top: 0;
	margin-left: 1.875rem;
	margin-right: 1.875rem;
}
.fishka .mejs__playlist-current {
	position: static;
}
.fishka .mejs__horizontal-volume-slider {
	margin-left: 3.125rem;
	-webkit-box-ordinal-group: 5;
	-ms-flex-order: 4;
	order: 4;
	width: 3.75rem;
	height: .0625rem;
	position: absolute;
	bottom: 1.25rem;
	right: 1.25rem;
}
.fishka .mejs__horizontal-volume-slider span {
	display: none;
}
.fishka .mejs__horizontal-volume-slider .mejs__horizontal-volume-total {
	width: 100%;
	height: .0625rem;
	top: 0;
	bottom: 0;
}
.fishka .mejs__horizontal-volume-slider .mejs__horizontal-volume-handle {
	width: .6875rem;
	height: .6875rem;
	display: block;
	background: #fff;
	border-radius: 50%;
	top: -.25rem;
	position: absolute;
}
.fishka .mejs__playlist-current p, .fishka .mejs-playlist-current p {
	max-width: 11.6875rem;
	margin: 1.3125rem 1.875rem .5rem 1.875rem;
	opacity: .5;
	white-space: nowrap;
	overflow: hidden;
	font-size: 0;
	font-style: normal;
	position: absolute;
	left: 1.375rem;
	top: 0;
}
.fishka .mejs__playlist-current p:before, .fishka .mejs-playlist-current p:before {
	content: '';
	position: absolute;
	z-index: 99999;
	left: -.625rem;
	top: 0;
	bottom: 0;
	width: 1.5625rem;
	background: -webkit-gradient(linear, left top, right top, from(#000), to(transparent));
	background: -webkit-linear-gradient(left, #000, transparent);
	background:      -o-linear-gradient(left, #000, transparent);
	background:         linear-gradient(to right, #000, transparent);
}
.fishka .mejs__playlist-current p:after, .fishka .mejs-playlist-current p:after {
	content: '';
	position: absolute;
	z-index: 99999;
	right: -.625rem;
	top: 0;
	bottom: 0;
	width: 1.5625rem;
	background: -webkit-gradient(linear, right top, left top, from(#000), to(transparent));
	background: -webkit-linear-gradient(right, #000, transparent);
	background:      -o-linear-gradient(right, #000, transparent);
	background:         linear-gradient(to left, #000, transparent);
}
.fishka .mejs__playlist-current p:nth-child(2), .fishka .mejs-playlist-current p:nth-child(2) {
	display: none;
}
.fishka .mejs__playlist-current p span, .fishka .mejs-playlist-current p span {
	font-size: .875rem;
	font-style: normal;
}
.fishka .video-wrapper__sound {
	width: 1.1875rem;
	height: 1.1875rem;
	top: auto;
	bottom: 2.25rem;
	right: 2.625rem;
	background-color: #000;
}
.fishka .video-wrapper__sound svg {
	width: 1.1875rem;
	height: 1.1875rem;
}
.fishka .video-wrapper__sound svg * {
	fill: #000;
}
.fishka .video-wrapper__sound .video-wrapper__sound_overlay {
	background-color: #fff;
}
.fishka .mejs__button.mejs__volume-button.mejs__mute, .fishka .mejs__button.mejs__volume-button.mejs__unmute, .fishka .mejs__button.mejs__loop-button.mejs__loop-off, .fishka .mejs__time.mejs__currenttime-container, .fishka .mejs__time.mejs__duration-container, .fishka .mejs__time-handle, .fishka .mejs__button.mejs__playpause-button.mejs__replay {
	display: none;
}
.fishka.small {
	width: 9.375rem;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	    flex-direction: column;
}
.fishka.small .open-player {
	display: block !important;
}
.fishka.small .close-player {
	display: none !important;
}
.fishka.small .mejs__container.mejs__audio, .fishka.small .mejs-container.mejs-audio {
	width: 7.5rem !important;
	min-width: 0 !important;
}
@media (min-width: 1200px) {
	.fishka.small .mejs__container.mejs__audio, .fishka.small .mejs-container.mejs-audio {
		height: 4.375rem;
	}
}
.fishka.small .mejs__time-rail {
	display: none;
}
.fishka.small .mejs__button.mejs__next-button {
	display: none;
}
.fishka.small .mejs__button.mejs__prev-button {
	display: none;
}
.fishka.small .video-wrapper__sound {
	display: none;
}
.fishka.small .mejs__playlist-current p {
	display: none;
}
.fishka.small .mejs__horizontal-volume-slider {
	display: none;
}
.fishka.small .mejs__button.mejs__playpause-button.mejs__play {
	margin-left: 1.5625rem;
	margin-right: 1.5625rem;
}
.fishka.small .mejs__button.mejs__playpause-button.mejs__pause {
	margin-left: 1.5625rem;
	margin-right: 1.5625rem;
}
.fishka .mejs__loop-button {
	display: none;
}
.link-volta {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	color: #52555a;
	font-size: .75em;
	float: right;
}
.link-volta svg {
	margin-left: .5625rem;
}
.link-volta svg path, .link-volta svg polygon {
	fill: #52555a;
	-webkit-transition: all .2s ease;
	     -o-transition: all .2s ease;
	        transition: all .2s ease;
}
.link-volta:hover {
	color: #add8e6;
}
.link-volta:hover svg path, .link-volta:hover svg polygon {
	fill: #add8e6;
}
.parked {
	position: relative;
	height: 100vh;
	padding-top: 4.125rem;
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,.6)), to(rgba(0,0,0,.6))), url('../img/parked/parked.jpg') no-repeat center/cover;
	background: -webkit-linear-gradient(bottom, rgba(0,0,0,.6), rgba(0,0,0,.6)), url('../img/parked/parked.jpg') no-repeat center/cover;
	background:      -o-linear-gradient(bottom, rgba(0,0,0,.6), rgba(0,0,0,.6)), url('../img/parked/parked.jpg') no-repeat center/cover;
	background:         linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.6)), url('../img/parked/parked.jpg') no-repeat center/cover;
}
.parked .btn {
	margin-bottom: 1.875rem;
}
.parked .nav-logo {
	max-width: 16.125rem;
}
.parked h4 {
	position: relative;
	color: #fff;
	margin-top: 9.5rem;
	line-height: 1.5em;
}
.parked h4:before {
	content: '';
	position: absolute;
	left: 0;
	top: -1.875rem;
	width: 3.125rem;
	height: .125rem;
	background-color: #fff;
}
.parked p {
	color: #fff;
	margin-top: 5.875rem;
	margin-bottom: 3.25rem;
	line-height: 1.4em;
}
@media (min-width: 768px) and (max-width: 1199px) {
	.parked {
		height: auto;
	}
	.parked .btn-white {
		font-size: .8125em;
		-webkit-box-flex: 1;
		-ms-flex: 1;
		    flex: 1;
		margin-right: .9375rem;
	}
	.parked .btn-white:last-child {
		margin-right: 0;
	}
}
@media (max-width: 767px) {
	.parked {
		height: auto;
		padding-top: 1.1875rem;
		padding-bottom: 6.25rem;
		padding-right: 1.75rem;
		padding-left: 1.75rem;
	}
	.parked .nav-logo {
		max-width: 9.875rem;
	}
	.parked .btn {
		max-width: 100%;
		margin-bottom: 1.875rem;
	}
	.parked h4 {
		margin-top: 3.4375rem;
		line-height: 1.6em;
	}
	.parked h4:before {
		top: -1.375rem;
	}
	.parked p {
		line-height: 1.7em;
		margin-top: 3.4375rem;
		margin-bottom: 2.25rem;
	}
}
body.brow-msie .fishka {
	display: none !important;
}
body.brow-msie .nav-panel__list li a, body.brow-msie .nav-panel__sublist li a {
	height: 1.625rem;
}
body.brow-msie .slide-index__text {
	overflow: visible;
}
body.brow-msie .slide-index__text__links {
	display: block;
}
body.brow-msie .slide-index__text .social-links {
	display: block;
	position: relative;
	top: -1.125rem;
}
body.brow-msie .slide-index__text .social-links li {
	display: inline-block;
	width: 1.5rem;
}
body.brow-msie .footer-list li a {
	height: 1.3125rem;
}
body.brow-msie footer .social-links li a {
	width: 1.5rem;
}
body.brow-msie .gate__logo {
	height: 2.375rem;
}
body.brow-msie .form-anketa .input-group {
	width: 100%;
}